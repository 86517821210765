import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import More from "../../../assets/moreGray.svg";
import "../../styles/dashboard.pc.css";
import "../../styles/popUp.pc.css";
import useOutsideAlerter from '../../../customHooks/useOutsideAlert';
import { BoardContext, IBoardForProxy } from '../../../pages/Board/Board';
import { IColumnDisplay } from '../../../models/IColumnDisplay';
import {IHasPermission} from "../../../models/IChekRole";
import {Context} from "../../../index";
import {AppContext} from "../../../App";

export interface IMoreButtonProps {
    onClickFunc: (e: any) => void;
    data: string[],
    typeForKey: string,
    open: boolean,
    setOpen: (open: boolean) => void,
    columnsChoices?: IColumnDisplay[],
    boardChoices?: IBoardForProxy[],
    taskId?: number,
    userAccess: IHasPermission[]
}



const BtnMore: FC<IMoreButtonProps> = ({ onClickFunc, data, typeForKey, open, setOpen, columnsChoices, boardChoices, taskId, userAccess }) => {
    const [showColumns, setShowColumns] = useState<boolean>(false);
    const outsideAlerterRef: any = useOutsideAlerter(() => setOpen(false));
    const { handleMoveTask, handleMoveTaskBoard } =
        useContext(BoardContext);

    const [editTask, setEditTask] = useState(false);
    // const [moveTask, setMoveTask] = useState(false);
    const [delTask, setDelTask] = useState(false);
    const { store } = useContext(Context);
    const { showToast } = useContext(AppContext);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDelTask(true);
                    }
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTask(true);
                    }
                })
            }
        })

        if (store.user.email == "admin@bpmlab.ru") {
            setDelTask(true);
            setEditTask(true);
        }
    }, [userAccess]);

    return (
        <div key={typeForKey} className="button-base" ref={outsideAlerterRef} onMouseLeave={() => setShowColumns(false)}>
            <div onClick={() => setOpen(true)}>
                <img src={More} draggable="false"/>
            </div>
            {open &&
                <div>
                    <div className='popUpWrapper'>
                        <div className='popUpContainer' >
                            {data.map(i => (
                                (i === "Редактировать") ?
                                    <div key={i} className='popUpItem' onClick={(e: any) => (editTask ? onClickFunc(e) : showToast("У вас нет прав для редактирования задачи!"))}>
                                        <div className='p_12_popUP' key={i}>
                                            {i}
                                        </div>
                                    </div>
                                : (i === "Удалить") ?
                                    <div key={i} className='popUpItem' onClick={(e: any) => (delTask ? onClickFunc(e) : showToast("У вас нет прав для удаления задачи!"))}>
                                        <div className='p_12_popUP' key={i}>
                                            {i}
                                        </div>
                                    </div>
                                : (i === "Переместить") ?
                                    (<div key={i} className='popUpItem'>
                                        <div className='p_12_popUP' key={i}
                                             onMouseEnter={() => setShowColumns(true)}>{i}</div>
                                    </div>) :
                                    (<div key={i} className='popUpItem'>
                                        <div className='p_12_popUP' onMouseLeave={() => setShowColumns(false)}
                                             key={i} onClick={(e: any) => onClickFunc(e)}>{i}</div>
                                    </div>)
                                )
                            )}
                        </div>
                        {showColumns && taskId ? (
                            <div className="popUpWrapper_inner ">
                                <div className="popUpContainer">
                                    <label className='popUpLabel'>
                                        Переместить в колонку
                                    </label>
                                    {columnsChoices?.map((c) => (
                                        <div className="popUpItem_inner p_12_popUP"
                                            onClick={() => handleMoveTask(taskId, c.id, 0)}>
                                            {c.name.length > 20 ? c.name?.slice(0, 20) + "..." : c.name}
                                        </div>
                                    ))}
                                    <label className='popUpLabel'>
                                        На доску
                                    </label>
                                    {boardChoices?.map((b) => (
                                        <div className="popUpItem_inner p_12_popUP"
                                            onClick={() => handleMoveTaskBoard(taskId, b.id)}>
                                            {b.name.length > 20 ? b.name?.slice(0, 20) + "..." : b.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            }
        </div>
    )
}

export default observer(BtnMore);