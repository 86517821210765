import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import settingsButtonBlue from "../../../assets/SettingCogBlue.svg"
import settingsButtonGray from "../../../assets/settings.svg"
import {AppContext} from "../../../App";


export interface ISettingsButtonProps {
    onClickFunc: () => void;
    permission: boolean;
}

const BtnSettings: FC<ISettingsButtonProps> = ({onClickFunc, permission}) => {
    const { showToast } = useContext(AppContext)

    return (
        <div className="button-base" onClick={() => permission ? onClickFunc() : showToast("У вас нет прав для редактирования канбан-доски!")}>
            <img src={permission ? settingsButtonBlue : settingsButtonGray} />
        </div>
    )
}

export default observer(BtnSettings);