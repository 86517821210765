import React, { FC, useContext, useEffect } from 'react';
import { Context } from "../../index";
import MenuForm from "../../component/LeftMenu/MenuForm";
import Header from "../../component/Header/Header";
import "../../globalstyles/page.pc.css"
import { useNavigate } from "react-router-dom";
import '../../pages/Main/MainPage.css'
import Project from '../Project/Project';
import { AppContext } from '../../App';

const Arhive: FC = (index) => {
    const { store } = useContext(Context);
    const navigate = useNavigate();
    const { setBreadCrumb, setNavPanelHighlight } = useContext(AppContext);

    useEffect(() => {
        setBreadCrumb([{label: "Архив", url: "/archive"}])
        setNavPanelHighlight("archive");
    }, []);

    
    return (
        <div>В разработке...</div>
    );
    
}

export default Arhive;