import React, { FC, useContext, useEffect, useState } from "react";
import { IColumnDisplay } from "../../../models/IColumnDisplay";
import { BoardContext } from "../../../pages/Board/Board";
import "./ColumnWeightInput.css"
import {values} from "mobx";

interface IColumnWeightInputProps {
    column?: IColumnDisplay;
    onEditWeight?: (weight: number) => void;
    onChangeWeight?: (weight: number) => void;
    edit: boolean;
}

const ColumnWeightInput: FC<IColumnWeightInputProps> = ({
    column,
    onEditWeight,
    onChangeWeight,
    edit,
}) => {
    const [maxWeight, setMaxWeight] = useState<number>(100);
    const [weight, setWeight] = useState<number>(column?.weight ?? 0);

    const { columns } = useContext(BoardContext);

    useEffect(() => {
        let maxW = 100;
        columns.forEach((c) => {
            if (c.id !== column?.id) maxW -= c.weight ? c.weight : 0;
        });
        setMaxWeight(maxW);
    }, [columns, column]);

    const handleChangeWeight = (e: any) => {
        const newWeight = Number(e.target.value);
        if (newWeight >= 0 && newWeight <= maxWeight) {
            setWeight(newWeight);
            if (onChangeWeight) onChangeWeight(newWeight);
        }
    };
    const onClickWeight = (e: any) => {
        const weight = Number(e.target.value);
        if (weight === 0 ) {
            e.target.value = ''
        }
    };

    return (
        <div className="color__picker--block">
            <div className="color__picker--block__name">
                Вес колонки
            </div>
            <div className="color__picker--block__input">
                <input
                    type="number"
                    min={0}
                    max={maxWeight}
                    onClick={onClickWeight}
                    value={weight}
                    onInput={handleChangeWeight}
                ></input> 
                {edit && onEditWeight ? (
                    <button onClick={() => onEditWeight(weight)}>
                        Изменить вес
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default ColumnWeightInput;
