import {FC, useContext, useRef, useState} from 'react';
import "./TaskPerson.css"
import {TaskContext} from '../../../pages/Task/Task';
import Avatar from '../../Shared/Avatar/Avatar';
import { getFileUrlFromId } from '../../../helpers/getFileUrlFromId';


interface ITaskPerson {
    permission: boolean,
}

const TaskPerson: FC<ITaskPerson> = ({permission}) => {
    const [showFullList, setShowFullList] = useState(false);
    const listRef = useRef(null);
    const {allPerson} = useContext(TaskContext);

    const handleMouseEnter = () => {
        setShowFullList(true);
    };

    const handleMouseLeave = () => {
        setShowFullList(false);
    };

    return (
        <div className="task__person--block">
            <div className="task__person--block__executor">
                <div className="task__person--block--name">
                    Исполнитель
                </div>
                    <div className="task__person--block--person">

                        <div className={permission ? "task__person--block--img" : ''}>
                            { permission ? 
                            <Avatar url={getFileUrlFromId(allPerson?.executor.photoId)} size='s'/> : ""}
                        </div>
                        <div className="task__person--block--name__person">
                            { permission ? allPerson?.executor.surname + " " + allPerson?.executor.name?.slice(0, 1) : ''}
                        </div>

                    </div>
            </div>

                    <div className="task__person--block__subtasks">
                    <div className="task__person--block--name">
                    Подзадачи - { permission ? allPerson?.subTask ? 1 : 0 : 0}
            </div>
            <div className="task__person--block__subtasks--name">
                {allPerson?.subTask ? allPerson?.subTask.length > 25 ? allPerson?.subTask.slice(0, 25) + "..." : allPerson?.subTask : "Подзадач нет"}
            </div>
        </div>
    <div className="task__person--block__group">
        <div className="task__person--block--name">
            Подписчики
        </div>
        <div>
            <div className="task__person--block--line__group" onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave} ref={listRef}>
                {permission ? allPerson?.group.slice(0, 5).map((item, index) => (
                    <div key={"person-" + index} className="task__person--block--name__person--group">
                        <div className="task__person--block--img">
                            <Avatar url={getFileUrlFromId(item.photoId)} size='s'/>
                        </div>
                        {/* <div className="task__person--block--name__person--in">
                            {item.surname?.slice(0, 1) + "" + item.name?.slice(0, 1)}
                        </div> */}
                    </div>
                )) : ''}

                {showFullList &&
                    allPerson?.group.slice(5).map((item, index) => (
                        <div key={"person-" + index} className="task__person--block--name__person--group">
                            <div className="task__person--block--img">
                                <Avatar url={getFileUrlFromId(item.photoId)} size='s'/>
                            </div>
                            {/* <div className="task__person--block--name__person--in">
                                {item.surname?.slice(0, 1) + "" + item.name?.slice(0, 1)}
                            </div> */}
                        </div>
                    ))}

                {allPerson?.group?.length! > 5 && !showFullList && (
                    <div className="task__person--block--more">
                        +{allPerson?.group?.length! - 5}
                    </div>
                )}
            </div>
        </div>
    </div>
    <div className="task__person--block__autor">
        <div className="task__person--block--name">
            Автор
        </div>
        <div className="task__person--block--person">
            <div className="task__person--block--img">
                { permission ? <Avatar url={getFileUrlFromId(allPerson?.autor.photoId)} size='s'/> : ''}
            </div>
            <div className="task__person--block--name__person">
                {permission ? allPerson?.autor.surname + " " + allPerson?.autor.name?.slice(0, 1) : ''}
            </div>
        </div>
    </div>
</div>
)
    ;
};

export default TaskPerson;