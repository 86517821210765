import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface IGuardedRouteProps {
    condition: boolean;
    redirectPath?: string;
    children: ReactNode;
}

const GuardedRoute: FC<IGuardedRouteProps> = ({
    condition,
    redirectPath,
    children,
}) => {
    if (!condition) {
        return <Navigate to={redirectPath ?? "/login"} replace />;
    }
    return <>{children}</>;
};

export default GuardedRoute;
