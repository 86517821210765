export class DoubleKeyMap<K1, K2, V> {
    private map: Map<string, V> = new Map();

    constructor(values: [K1, K2, V][]) {
        values.forEach((item) => {
            this.set(item[0], item[1], item[2]);
        });
    }

    set(key1: K1, key2: K2, value: V): void {
        const combinedKey = JSON.stringify([key1, key2]);
        this.map.set(combinedKey, value);
    }

    get(key1: K1, key2: K2): V | undefined {
        const combinedKey = JSON.stringify([key1, key2]);
        return this.map.get(combinedKey);
    }

    delete(key1: K1, key2: K2): boolean {
        const combinedKey = JSON.stringify([key1, key2]);
        return this.map.delete(combinedKey);
    }

    has(key1: K1, key2: K2): boolean {
        const combinedKey = JSON.stringify([key1, key2]);
        return this.map.has(combinedKey);
    }
}
