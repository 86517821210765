import React, {FC, useContext, useEffect, useRef} from 'react';
import {observer} from "mobx-react-lite";
import TaskIconBtnGrey from "../../../assets/taskIconBtnGrey.svg";
import TaskIconBtn from "../../../assets/taskIconBtn.svg";
import {Toast} from 'primereact/toast';
import {FileUpload} from 'primereact/fileupload';
import "./TaskBtnAdd.css"
import {AppContext} from "../../../App";


export interface IAddButtonProps {
    onClickFunc: (e: any) => void;
    type: string;
    permission?: boolean;
}


const TaskBtnAdd: FC<IAddButtonProps> = ({onClickFunc, type, permission}) => {
    const {showToast} = useContext(AppContext);

    if (type == 'file') {
        return (
            <div>
                <label className="input-file input-file__task--size">
                    {permission ? (
                        <input onChange={(e) => {
                            onClickFunc(e)
                        }} type="file"/>
                    ) : (null)}
                    <span className={permission ? "input-file__task-btn": "input-file__task-btn-grey"}>
                        <img src={permission ? TaskIconBtn : TaskIconBtnGrey}
                             onClick={() => (permission ? null : showToast("У вас нет прав на добавление файлов!"))}/>
                    </span>
                </label>
            </div>
        )
    } else {
        return (
            <div key={type} className="button_add" onClick={onClickFunc}>
                <img src={TaskIconBtn}/>
            </div>
        )
    }


}

export default observer(TaskBtnAdd);



