import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import TaskFiles from "../TaskFiles/TaskFiles";
import {
    IFileFilterOptions,
    IFileFilters,
    IFilesDisplay,
    IFileTaskFilters,
    IGetFilesDisplayData,
    IGetFilesTaskDisplayData
} from "../../../models/FileModels";
import {Context} from "../../../index";
import {useParams} from "react-router-dom";
import TaskPerson from "../TaskPerson/TaskPerson";
import {ICheckRole, IHasPermission} from "../../../models/IChekRole";
import FunctionSystem from "../../../models/functionCode/functionsSystem";


// export const TaskRightInfoContext = createContext<any>(null);

const TaskRightInfo: FC = (index) => {
    const {id} = useParams();
    const {store} = useContext(Context);



    // Ограничение прав
    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);
    const [viewTaskInfo, setViewTaskInfo] = useState(false);

    //Ограничение доступа
    useEffect(() => {
        (async () => {
            try {
                const functionClass = new FunctionSystem()
                const newCheckRole: ICheckRole = {
                    projectId: Number(id),
                    boardId: undefined,
                    functionCodes: functionClass.getTaskFunction
                };

                const checkThisRole = await store.hasPermission(newCheckRole)

                setUserAccess(checkThisRole);
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);


    useEffect(() => {
        try {
            userAccess.forEach((xx) => {
                if (xx.functionCode == "TaskAction") { //Информация по задаче
                    xx.permissions.forEach((yy) => {
                        if (yy.permissionCode == "view" && yy.isGranted == true) {
                            setViewTaskInfo(true);
                        }
                    })
                }
                if (store.user.email == "admin@bpmlab.ru") {
                    setViewTaskInfo(true);
                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [userAccess]);


    return (
        <div className="task--right__info--menu">
            <div>
                <TaskFiles userAccess={userAccess}/>
            </div>
            <div>
                <TaskPerson permission={viewTaskInfo}/>
            </div>

            {/*  Исполнитель  */}
            {/*  Подзадачи  */}
            {/*  Группа  */}
            {/*  Автор  */}
        </div>
    );
};

export default TaskRightInfo;