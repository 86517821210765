import React, { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import moveArrowBlue from "../../../assets/MoveArrowBlue.svg"
import moveArrowGray from "../../../assets/Arrow_Sub_Right_Down.svg"
import {AppContext} from "../../../App";


export interface IMoveButtonProps {
    onClickFunc: () => void;
    permission: boolean;
}

const BtnMove: FC<IMoveButtonProps> = ({onClickFunc, permission}) => {
    const { showToast } = useContext(AppContext);

    return (
        <div className="button-base" onClick={() => permission ? onClickFunc() : showToast("У вас нет прав для передвижения задач(и)!")}>
            <img src={permission ? moveArrowBlue : moveArrowGray} />
        </div>
    )
}

export default observer(BtnMove);