import React, {useEffect, useState} from 'react';
import { observer } from "mobx-react-lite";
import { ProgressBar } from 'primereact/progressbar';
import "../../styles/generalInformation.pc.css";
import {IProjectProgressInfo} from "../../../models/ProjectModels";

interface IProjectProgressProps {
    data: IProjectProgressInfo | undefined;
}

function toDisplayDate(dateStr: any): string {
    try {
        let date = new Date(dateStr);
        const padWithZero = (value: number) => value.toString().padStart(2, '0');
        const day = padWithZero(date.getDate());
        const month = padWithZero(date.getMonth() + 1); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
        // return dateStr
    } catch (err: any) {
        return "-"
    }
}

const GeneralInformation: React.FC<IProjectProgressProps> = ({data}) => {

    const [progressInfo, setProgressInfo] = useState<IProjectProgressInfo | undefined>(undefined)

    return (
        <div id='project-progress-info-widget-component' className='widget_wrapper'>
            <div className='widget_header'>
                <div className="widjets_header_left">
                    <h2 className='widget-card-header-style'>Общие сведения</h2>
                </div>
            </div>
            <div className='genInfo_body'>
                <div className='genInfo_body_item'>
                    <p className='genInfo_body_item_p11 p_gray'>НАЧАЛО/ОКОНЧАНИЕ ПРОЕКТА</p>
                    <p className='genInfo_body_item_p11 p_blue'>{data?.startDate ? toDisplayDate(data?.startDate) : ""}/{`${data?.endDate ? toDisplayDate(data?.endDate) : ""}`}</p>
                </div>
                <div className='genInfo_body_item'>
                    <p className='genInfo_body_item_p11 p_gray'>ЗАДАЧ В РАБОТЕ</p>
                    <p className='genInfo_body_item_p11 p_blue'>{data?.tasksInProgress}</p>
                </div>
                <div className='genInfo_body_item'>
                    <p className='genInfo_body_item_p11 p_gray'>ВЫПОЛНЕНО ЗАДАЧ</p>
                    <p className='genInfo_body_item_p11 p_blue'>{data?.tasksCompleted}</p>
                </div>
                <div className='genInfo_body_item'>
                    <p className='genInfo_body_item_p11 p_gray'>ПРОСРОЧЕНО ЗАДАЧ</p>
                    <p className='genInfo_body_item_p11 p_red'>{data?.tasksOverdue}</p>
                </div>
                <div className='genInfo_body_item'>
                    <p className='genInfo_body_item_p11 p_gray'>ПРОГРЕСС</p>
                    <p className='genInfo_body_item_p11 p_blue'>{data?.progressPercent}%</p>
                </div>

                <ProgressBar value={data?.progressPercent ?? 0}></ProgressBar>
            </div>
        </div>
    )
}

export default observer(GeneralInformation);