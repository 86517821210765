import $api from "..";
import {AxiosResponse} from "axios";
import {IUser, ISustemPerson, IUserWithStatus, IEditUserInfo} from "../models/IUser";
import {IGetAllUsersWithSystemRoles} from "../models/IGetAllUsersWithSystemRoles";
import {IUserStatus} from "../models/response/IUserStatusResponse";

export default class UserService {
    static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>('/getUser');
    }

    static async getAllUserStatuses(): Promise<AxiosResponse<IUserStatus[]>> {
        return $api.get<IUserStatus[]>('/getAllUserStatuses');
    }

    static async getDataLinkPerson(link: string): Promise<AxiosResponse<IUserWithStatus>> {
        return $api.get<IUserWithStatus>(`/getDataLinkPerson?link=${link}`);
    }

    static getSystemPersonWithRoles(): Promise<AxiosResponse<ISustemPerson>> {
        return $api.get<ISustemPerson>('/getSystemPersonWithRoles');
    }

    static addRole(userId: number, roleId: number): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>('addRole', {userId, roleId});
    }

    static async getAllUsersWithSystemRoles(projectId: number | undefined): Promise<AxiosResponse<IGetAllUsersWithSystemRoles[]>> {
        return $api.get<IGetAllUsersWithSystemRoles[]>(`System/getSystemPersonWithRoles?projectId=${projectId}`);
    }

    static async editUser(body: IEditUserInfo): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>("/editUser", body);
    }

}