import React, {useContext, useRef, useState} from 'react';
import { observer } from "mobx-react-lite";
import "../../styles/team.pc.css";
import "../../styles/table.pc.css";
import Search from "../Search/Search"
import '../../../globalstyles/dataTableStyles.css'
import PersonLineDisplay from "../PersonLineDisplay";
import {Context} from "../../../index";
import { initials } from '../../../helpers/Inicials';
import { ITeamMemberDisplay } from '../../../models/TeamModels';

const PAGE_SIZE = 5

export interface ITeamFilter {
    projectId: number,
    role: number[] | undefined,
    name: string | undefined
}

interface IPersonSelectProps {
    members: ITeamMemberDisplay[];
    scrollEnded: boolean;
    loadPage: (skip: number, take: number, filters: ITeamFilter) => void;
    updateFilters: (filters: ITeamFilter) => void;
    selectPerson: (personId: number) => void;
}

const PersonSelector: React.FC<IPersonSelectProps> = ({members, scrollEnded, loadPage, updateFilters, selectPerson}) => {
    const { store } = useContext(Context);
    const page = useRef<number>(0)
    const filters = useRef<ITeamFilter>({
        projectId: store.currentProjectId,
        role: undefined,
        name: ''
    });
    const [searchSubstring, setSearchSubstring] = useState<string>('');
    const onScroll = (e: any) => {
        checkIfScrolledToBottom(e)
    };

    function checkIfScrolledToBottom(e: any) {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;
        if ((1 + scrollTop + offsetHeight >= scrollHeight) && (!scrollEnded)) {
            page.current++
            loadPage(page.current * PAGE_SIZE, PAGE_SIZE, filters.current)
        }
    }

    // function initials(str: string) {
    //     return str
    //         .split(/\s+/)
    //         .map((w, i) => (i ? w.substring(0, 1).toUpperCase() + "." : w))
    //         .join(" ");
    // }

    const personRowDisplay = (rowData: ITeamMemberDisplay) => {
        return <PersonLineDisplay name={initials(rowData.responsibleName)} photoId={rowData.responsiblePhotoId}/>
    };

    function onPersonClick(key: number, e: any){
        selectPerson(key);
        getPersonMore(e);
        
    }

    function getPersonMore(e: any) {
        if(members.length <= 5){
            checkIfScrolledToBottom(e);
        }
    }
    return (
        <div className='person-selector-container'>
            <div className='widgets_header'>
                <Search onSubmitFunc={() => {}} defaultValue={searchSubstring} changeSearchValue={setSearchSubstring}/>
            </div>
            <div onScroll={onScroll} style={{overflowY: "auto", height: '130px'}}>
                <table>
                    <tbody>
                    {members?.filter(xx=> (xx.responsibleName as string)?.toLowerCase().includes(searchSubstring?.toLowerCase()) ||
                        (xx.position as string)?.toLowerCase().includes(searchSubstring?.toLowerCase()))?.map(member =>
                        <tr key={member.id} onClick={(e) => onPersonClick(member.id, e)} className='person-selection-item'>
                            <td>{personRowDisplay(member)}</td>
                            <td className='task-type-text-position ellipses' >{member.position}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default observer(PersonSelector);