import { FC } from "react";
import { getFileUrlFromId } from "../../helpers/getFileUrlFromId";
import Avatar from "./Avatar/Avatar";
import "./MisceleniousStyles.css";

interface IPersonLineDisplay {
    name: string;
    photoId?: number;
}

const PersonLineDisplay: FC<IPersonLineDisplay> = ({ name, photoId }) => {
    return (
        <div className="line-person-display-widget nonselectable">
            <div className="line-profile-image-container">
                {/*<img className='line-profile-image' src={params.picture}/>*/}
                <Avatar size="s" url={getFileUrlFromId(photoId)} />
            </div>
            <div title={name} className="task-type-text ellipses">
                {name}
            </div>
        </div>
    );
};
export default PersonLineDisplay;
