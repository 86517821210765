import { FC } from "react";
import Arrow from "../../../assets/arrowDown.svg";
import ArrowWhite from "../../../assets/arrowDownWhite.svg";
import "../DefaultButtons.css";
import "./ArrowButton.css";

interface IArrowButtonProps {
    open: boolean;
    onClick: (e: any) => void;
    whiteArrow?: boolean;
}

const ArrowButton: FC<IArrowButtonProps> = ({ open, onClick, whiteArrow }) => {
    return (
        <button
            onClick={(e) => onClick(e)}
            className="button-base arrow-button"
        >
            <img
                src={whiteArrow ? ArrowWhite : Arrow}
                alt="arrow-button"
                style={{ transform: `${open ? "rotate(180deg)" : ""}` }}
            />
        </button>
    );
};

export default ArrowButton;
