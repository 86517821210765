import React, { FC, useContext, useEffect } from "react";
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { Route, useLocation, useNavigate } from "react-router-dom";
import Confirmation from "../../component/Authorization/Confirmation/Confirmation";
import RecoverPasswordForm from "../../component/Authorization/RecoverPassword/RecoverPasswordForm";

//to commit
const RecoverPassword: FC = (index) => {
    const { store } = useContext(Context);
    const navigate = useNavigate();

    if (!store.isAuth) {
        return (
            <div>
                <RecoverPasswordForm />
            </div>
        );
    } else if (!store.isActivate) {
        return (
            <div>
                <Confirmation />
            </div>
        );
    } else {
        navigate("/projects");
        return null;
    }
};

export default observer(RecoverPassword);
