import React, {FC, useContext, useEffect, useState} from 'react';
import {IExcludePermissionFunction, IExcludePermissionFunctionRoles, IExcludePermissionFunctionFunctionGroups, IExcludePermissionFunctionSystemFunctions, IExcludePermissionFunctionAccessPermissions} from "../../../models/IExcludePermissionFunction";
import {Context} from "../../../index";
import ArrowSub from "../../../assets/arrowDown.svg";
import ArrowRight from "../../../assets/ArrowRight.svg";
import "./ProjectEditRoleBoard.css"
import {ISaveExcludePermission} from "../../../models/ISaveExcludePermission";
import {all} from "axios";
import {IRestrictAccess, IRestrictAccessBoardsSave} from "../../../models/IRestrictAccess";
import {useParams} from "react-router-dom";
import {IGetOneRole, IRoleAvailablePermissions} from "../../../models/IGetOneRole";
import { access } from 'fs';
import {AppContext} from "../../../App";
// import RestrictAccess from "../RestrictAccess/RestrictAccess";
import {IGetAllUsersWithSystemRolesChecked} from "../../../models/IGetAllUsersWithSystemRoles"

interface ItemPersonRole {
    name: string;
    code: string;
    personRoleId: number;
}



interface IProjectEditRoleBoard {
    personId: ItemPersonRole | null | undefined
    closeFunc: () => void;
    roleEdit?: IExcludePermissionFunction | undefined
    projectId: number | null | undefined;
}

enum IPermissionCode {
    "create" = "Создание",
    "view" = "Просмотр",
    "delete" = "Удаление",
    "edit" = "Редактирование",
    "full" = "Все"
}

enum IEditPermissionCode {
    "create" = "create",
    "view" = "view",
    "delete" = "delete",
    "edit" = "edit",
    "full" = "full"
}

const ProjectEditRoleBoard: FC<IProjectEditRoleBoard> = ({personId, closeFunc, roleEdit,projectId}) => {
    const {store} = useContext(Context);
    const [userRole, setUserRole] = useState<IExcludePermissionFunction | undefined>();
    const [page, setPage] = useState<boolean>(true);
    const roleIdG = roleEdit?.roles.findIndex(role => role.personRoleId === personId!.personRoleId)!;
    const [boardsData, setBoardsData] = useState<IRestrictAccess>();
    const { id } = useParams();
    const [allUsers, setAllUsers] = useState<IGetAllUsersWithSystemRolesChecked[] | undefined>([]);
    const { showToast } = useContext(AppContext);

    function onCheckEvent(id: number) {
        let boardIndex = boardsData?.boards.findIndex(board => board.id === id)!;
        let chekIn = boardsData?.boards[boardIndex].personRoleIds?.includes(userRole!.roles[roleIdG].personRoleId);
        if (chekIn) {
            let data = boardsData?.boards.map(xx => {
                if (xx.id == id) {
                    xx.personRoleIds = xx.personRoleIds?.filter(yy => yy != userRole!.roles[roleIdG].personRoleId)
                }
                return xx;
            })
            let newData: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));
            newData.boards = data!;
            setBoardsData(newData);
        } else {
            let newData: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));
            if (newData.boards.find(xx => xx.id == id)?.personRoleIds == undefined) {
                newData.boards[newData.boards.findIndex(board => board.id === id)].personRoleIds = [];

                newData.boards[newData.boards.findIndex(board => board.id === id)].personRoleIds!.push(userRole!.roles[roleIdG].personRoleId);
            } else {
                newData.boards[newData.boards.findIndex(board => board.id === id)].personRoleIds!.push(userRole!.roles[roleIdG].personRoleId);
            }
            setBoardsData(newData)
        }
    }

    function handleViewBlocks(arrowOne: string, arrowTwo: string, block: string) {
        const arrowOneFind = document.getElementById(arrowOne);
        const arrowTwoFind = document.getElementById(arrowTwo);
        const blockFind = document.getElementById(block);

        if (arrowOneFind && arrowTwoFind && blockFind) {
            if (arrowOneFind.style.display === 'none') {
                arrowOneFind.style.display = 'block';
            } else {
                arrowOneFind.style.display = 'none';
            }
            if (arrowTwoFind.style.display === 'none') {
                arrowTwoFind.style.display = 'block';
            } else {
                arrowTwoFind.style.display = 'none';
            }
            if (blockFind.style.display === 'none') {
                blockFind.style.display = 'block';
            } else {
                blockFind.style.display = 'none';
            }
        }
    }

    function saveExclude() {
        let allChoosePerm = userRole!.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);

        let allChoosePermSystem = userRole!.roles[roleIdG].functionGroups.flatMap(xx =>
            xx.systemFunctions.flatMap(yy => 
                yy.accessPermissions.map(zz => zz.id)
            )
        );

        const data: ISaveExcludePermission = {
            projectId: Number(id),
            personRoleId: userRole!.roles[roleIdG].personRoleId,
            accessPermissionIds: allChoosePerm
        };

        let checkBoard = boardsData?.boards.map((xx) =>
                xx).filter(x =>
                x.personRoleIds?.includes(userRole!.roles[roleIdG].personRoleId)).map(xx => xx.id);
        let restrict: IRestrictAccessBoardsSave[] = [];

        //TODO нужно доделать логику заполнения при отсутсвии досок
        if (checkBoard?.length! > 0) {
            let findUser = boardsData?.person.find(xx => xx.personRoleId == userRole!.roles[roleIdG].personRoleId);

            if (findUser) {
                checkBoard?.forEach((xx) => {
                    restrict.push({
                        personRoleId: findUser!.personRoleId,
                        projectId: undefined,
                        boardId: xx,
                        allColumns: true,  //TODO нужно доделать логику колонок
                        columnIds: undefined  //TODO нужно доделать логику колонок
                    })
                })
            }
        } else {
            let findUser = boardsData?.person.find(xx => xx.personRoleId == userRole!.roles[roleIdG].personRoleId);
            if (findUser) {
                restrict.push({
                    personRoleId: findUser!.personRoleId,
                    projectId: Number(id),
                    boardId: undefined,
                    allColumns: undefined,  //TODO нужно доделать логику колонок
                    columnIds: undefined  //TODO нужно доделать логику колонок
                })
            }
        }

        (async () => {
            try {
                if(restrict?.length){
                    const boardSave = await store.saveRestrict(restrict);
                }

                const res = await store.saveExcludeAccessPermission(data);
                closeFunc();
            } catch (err) {
                console.log(err)
            }
        })();
    }

    function changeGroup(groupId: number, checked: boolean) {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(
            JSON.stringify(userRole)
        );

        if (getData && getData.roles[roleIdG]) {
            let currentGroup = getData.roles[roleIdG].functionGroups.find((xx) => xx.id == groupId);
            let allSystemFunction = currentGroup!.systemFunctions.flatMap(xx => xx.accessPermissions);
            let allExclude = getData.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);

            if (checked == false) {
                allSystemFunction.forEach((xx) => {
                    if (!allExclude.includes(xx!.id)) {
                        getData!.roles[roleIdG].excludedPermissions.push({
                            id: 0,
                            accessPermission: xx!.id,
                            personRoleId: Number(personId!.code!)
                        });
                    }
                })
            } else {
                let newAccess = allSystemFunction.map(xx => xx!.id);
                getData.roles[roleIdG].excludedPermissions =
                    getData!.roles[roleIdG].excludedPermissions.filter(xx => !newAccess.includes(xx.accessPermission));
            }
        }
        setUserRole(getData);
    }

    function changeSystemFunctions(systemFunction: number, checked: boolean) {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(
            JSON.stringify(userRole)
        );

        if (getData && getData.roles[roleIdG]) {
            let group = getData.roles[roleIdG].functionGroups.map((xx) => xx);
            let system = group.flatMap(xx => {
                const foundSystemFunction = xx.systemFunctions.find((xx) => xx.id === systemFunction);
                return foundSystemFunction ? foundSystemFunction : undefined;
            });
            let allAccessItem = system
                .flatMap(xx => xx?.accessPermissions)
                .filter(accessPermission => accessPermission !== undefined);
            let allExclude = getData.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);
            if (checked == false) {
                allAccessItem.forEach((xx) => {
                    if (!allExclude.includes(xx!.id)) {
                        getData!.roles[roleIdG].excludedPermissions.push({
                            id: 0,
                            accessPermission: xx!.id,
                            personRoleId: Number(personId!.code!)
                        });
                    }
                })
            } else {
                let newAccess = allAccessItem.map(xx => xx!.id);
                getData.roles[roleIdG].excludedPermissions =
                    getData!.roles[roleIdG].excludedPermissions.filter(xx => !newAccess.includes(xx.accessPermission));
            }
        }
        setUserRole(getData);
    }

    function changeTab() {
        setPage(!page);
    }

    function chengeAccessPermissions(accessPermission: number) {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(
            JSON.stringify(userRole)
        );
        if (getData && getData.roles[roleIdG]) {
            let allAccessItem = getData.roles[roleIdG].excludedPermissions.map((xx) => xx.accessPermission);
            let findItem = allAccessItem.find((xx) => xx === accessPermission);

            if (findItem) {
                getData.roles[roleIdG].excludedPermissions =
                    getData.roles[roleIdG].excludedPermissions.filter(
                        (xx) => xx.accessPermission !== accessPermission
                    );
            } else {
                getData.roles[roleIdG].excludedPermissions.push({
                    id: 0,
                    accessPermission: accessPermission,
                    personRoleId: Number(personId!.code!)
                });
            }


        }
        setUserRole(getData);
    };

    function getUsers() {
        let dataRoleEdit = roleEdit;
        (async () => {
            try {
                const users = await store.getAllUsersWithSystemRoles(Number(id));
                // const dataRoles = await store.getAllRoles();

                console.log("uers", users);

                if (users) {
                    let data: IGetAllUsersWithSystemRolesChecked[] = [];
                    users.map((xx) => {
                        data.push({
                            chek: false,
                            user: xx
                        })
                    });

                    let currentSystemRoleUser = data.map(yy => yy.user).find(xx => dataRoleEdit!.roles.find(zz => {
                        if (zz.personRoleId == Number(xx.personRolesId)) {
                            return zz
                        }
                    }))

                    if (!dataRoleEdit || !currentSystemRoleUser) {
                        return console.log("Не удалось получить роли!");
                    }

                    let nowRoleEdit = dataRoleEdit?.roles[roleIdG];
                    let systemRoleId = currentSystemRoleUser.personRolesId;
                    let systemRoleFind = dataRoleEdit.roles.find(role => role.personRoleId == systemRoleId);

                    if (!systemRoleFind) {
                        return console.log("Такой роли не существует!"); 
                    }

                    let systemGroup = systemRoleFind!.functionGroups;
                    let systemFunc = systemGroup.flatMap(xx => xx.systemFunctions).filter(xx => xx.accessPermissions.length);

                    let newRoleGroups = nowRoleEdit.functionGroups.map(projGroup => {
                        if (systemGroup.map(xx => xx.id).includes(projGroup.id)) {
                            projGroup.systemRole = true;
                            projGroup.systemFunctions = projGroup.systemFunctions.filter(xx => xx.accessPermissions.length)
                            projGroup.systemFunctions = projGroup.systemFunctions.map(projFunc => {
                                if (systemFunc.map(xx => xx.id).includes(projFunc.id)) { 
                                    projFunc.systemRole = true;

                                    let sysFindFunc = systemFunc.find(xx => xx.functionCode == projFunc.functionCode)
                                    
                                    projFunc.accessPermissions = projFunc.accessPermissions.map(permission => {
                                        if (sysFindFunc && sysFindFunc.accessPermissions.map(xx => xx.code).includes(permission.code)) {
                                            permission.systemRole = true;
                                        } else {
                                            permission.systemRole = false;
                                        }

                                        return permission;
                                    });

                                    sysFindFunc && sysFindFunc.accessPermissions?.forEach(perm => {
                                        if (!projFunc.accessPermissions.some(p => p.code === perm.code)) {
                                            projFunc.accessPermissions.push({
                                                id: perm.id,
                                                systemRole: true,
                                                code: perm.code as "view" | "create" | "delete" | "edit" | "full"
                                            });
                                        }
                                    });
                                    
                                    return projFunc;
                                }
                                
                                projFunc.systemRole = false;
                                return projFunc;   
                            })

                            

                            return projGroup;
                        } 

                        return projGroup;
                    })
                    
                    systemGroup?.forEach(group => {
                        if (!newRoleGroups.some(p => p.id === group.id)) {
                            newRoleGroups.push({
                                id: group.id,
                                name: group.name,
                                systemRole: true,
                                systemFunctions: group.systemFunctions?.map(func => {
                                    if (func) {
                                        func.systemRole = true;
                                        func.accessPermissions = func.accessPermissions?.map(access => {
                                            console.log(access)
                                            if (access) {
                                                access.systemRole = true;
                                                return access;
                                            }
                                            return access;
                                        }) || [];
                                        return func;
                                    }
                                    return func;
                                }) || []
                            });
                        }
                    });
 
                    dataRoleEdit.roles[roleIdG].functionGroups = newRoleGroups;
                    setUserRole(dataRoleEdit);

                    //     const roleData = await store.getRole(currentSystemRoleUser.systemRole.id);
                    //     const roleDataCur = roleData?.functionGroups.filter(group => {
                    //         // console.log("До", group.availableFunctions)
                    //         group.availableFunctions = group.availableFunctions.filter(func => {
                    //             func.availablePermissions = func.availablePermissions.filter(xx => {
                    //                 if (roleData?.systemFunctions.find(gg => gg.id == func.id)?.permissions.map(yy => yy.code).includes(xx.code)) {
                    //                     xx.systemRole = true;
                    //                     return xx;
                    //                 }
                    //             })

                    //             // console.log("group.availableFunctions", group.availableFunctions)
                    //             // console.log("roleData?.systemFunctions", roleData?.systemFunctions)
                    //             if (roleData?.systemFunctions.map(xx => xx.functionCode).includes(func.functionCode)) {
                    //                 // console.log(func.functionCode)
                    //                 func.systemRole = true;
                    //                 return func;
                    //             }
                    //         })
                    //         // console.log("После", group.availableFunctions)
                    //         if (group.availableFunctions.length) {
                    //             group.systemRole = true;
                    //             // console.log("group", group)
                    //             return group
                    //         }
                    //     })

                        // console.log("dataRoleEdit", dataRoleEdit)

                        // dataRoleEdit!.roles[roleIdG].functionGroups.forEach(group => {
                        //     group.systemFunctions.forEach(functionItem => {
                        //         functionItem.accessPermissions = functionItem.accessPermissions.filter(permission => permission.id !== 0);
                        //     });
                        // });
                        // let projectSystemRole = dataRoleEdit
                        // console.log("projectSystemRole", projectSystemRole)
                        // console.log("roleDataCur?.length", roleDataCur)

                        // if (roleDataCur?.length) {
                        //     projectSystemRole!.roles[roleIdG].functionGroups = projectSystemRole!.roles[roleIdG].functionGroups.map(projectGroup => { //GROUP
                        //         if (roleDataCur.map(xx => xx.id).includes(projectGroup.id)) {
                        //             let indexGroupProject = projectSystemRole!.roles[roleIdG].functionGroups.findIndex(xx => //FIND GROUP PROJECT INDEX
                        //                 xx.id === projectGroup.id);
                        //             let groupSystem = roleDataCur.find(xx => xx.id == projectGroup.id)
                        //             let accessFuncProject = projectSystemRole!.roles[roleIdG].functionGroups[indexGroupProject].systemFunctions;
                        //             let funcSystem = groupSystem?.availableFunctions;
                        //
                        //             console.log("indexGroupProject", indexGroupProject)
                        //             console.log("groupSystem", groupSystem)
                        //
                        //             // roleDataCur
                        //
                        //             projectSystemRole!.roles[roleIdG].functionGroups[indexGroupProject].systemFunctions = projectSystemRole!.roles[roleIdG].functionGroups[indexGroupProject].systemFunctions.map(projectFunc => { //FUNCTION
                        //                 if(groupSystem?.availableFunctions.map(xx => xx.id).includes(projectFunc.id)) {
                        //                     let indexFuncProject = projectSystemRole!.roles[roleIdG].functionGroups[indexGroupProject].systemFunctions.findIndex(xx =>
                        //                         xx.id === projectFunc.id);
                        //                     let funcSystem = groupSystem?.availableFunctions.find(xx => xx.id == projectFunc.id)
                        //                     let accessPermProject = projectSystemRole!.roles[roleIdG].functionGroups[indexGroupProject].systemFunctions[indexFuncProject].accessPermissions;
                        //                     let permSystem = funcSystem?.availablePermissions
                        //
                        //                     const newAvailablePerm = accessPermProject.map(permProject => {
                        //                         const matchingPerm = permSystem?.find(permSystem => permSystem.code === permProject.code);
                        //
                        //                         if (matchingPerm) {
                        //                             permProject.systemRole = false;
                        //                         }
                        //
                        //                         return permProject;
                        //                     });
                        //
                        //
                        //                     permSystem?.forEach(perm => {
                        //                         if (!newAvailablePerm.some(p => p.code === perm.code)) {
                        //                             console.log("Добавление элемента из permSystem");
                        //                             newAvailablePerm.push({
                        //                                 id: perm.id,
                        //                                 systemRole: true,
                        //                                 code: perm.code as "view" | "create" | "delete" | "edit" | "full"
                        //                             });
                        //                         }
                        //                     });
                        //
                        //                     projectFunc.accessPermissions = newAvailablePerm;
                        //                     projectFunc.systemRole = true;
                        //                     return projectFunc
                        //                 }
                        //
                        //                 console.log("funcSystem", funcSystem)
                        //                 console.log("accessFuncProject", accessFuncProject)
                        //                 funcSystem?.forEach(func => {
                        //                     if (!accessFuncProject.some(p => p.functionCode === func.functionCode)) {
                        //                         console.log("Добавление элемента из permSystem");
                        //                         accessFuncProject.push({
                        //                             id: func.id,
                        //                             nameFunction: func.nameFunction,
                        //                             functionCode: func.functionCode ,
                        //                             systemRole: true,
                        //                             accessPermissions: func.availablePermissions
                        //                         });
                        //                     }
                        //                 });
                        //
                        //                 projectGroup.systemFunctions = projectSystemRole!.roles[roleIdG].functionGroups[indexGroupProject].systemFunctions;
                        //                 projectFunc.systemRole = true;
                        //                 return projectFunc
                        //             });
                        //             projectGroup.systemRole = true;
                        //             return projectGroup;
                        //         }
                        //         // else {
                        //             projectGroup.systemRole = false;
                        //             return projectGroup;
                        //         //     //Тут пушим новую группу
                        //         // }
                        //     })
                        // }

                    setAllUsers(data);
                }
            } catch(err) {
                console.log(err)
            }
        })();
    }

    useEffect(() => {

    }, [personId]);

    useEffect(() => {
        const data = roleEdit;
        setUserRole(data)
    }, []);

    useEffect(() => {
        (async () => {
            try {
                getUsers();

                if (projectId) {
                    const boardData = await store.restrictAccess(projectId)
                    setBoardsData(boardData);
                } else {
                    console.log("Не найден id проекта!")
                }

            } catch (err) {
                console.log(err)
            }
        })();
    }, [roleEdit]);

    return (
        <div className="project__edit--role__board--component">
            <div className="project__edit--role__board--component__in">
                <div className="project__edit--role__board--component__tab">
                    <div className={`project__edit--role__board--component__head ${page ? "project__edit--role__board--component__head--choise" : ""}`} onClick={changeTab}>
                        Права участника
                    </div>
                    <div className={`project__edit--role__board--component__head ${page ? "" : "project__edit--role__board--component__head--choise"}`} onClick={changeTab}>
                        Доступ к канбан доскам
                    </div>
                </div>
                {page ?
                    <div>
                        <div className="project__edit--role__board--component__edit">
                            <div>
                                <div className="project__edit--role__board--component__role">
                                    {userRole?.roles[roleIdG].roleName}
                                </div>
                                <div className="project__edit--role__board--component__block">
                                    {userRole?.roles[roleIdG].functionGroups.map((group) => (
                                        <div>
                                            <div className="project__edit--role__board--access__permissions">
                                                <div className="project__edit--role__board--arrow"
                                                     onClick={() => (handleViewBlocks("ShowGroupSub" + String(group.id),
                                                         "ShowGroupRight" + String(group.id), "ShowGroupBlock" + String(group.id)))}>
                                                    <img src={ArrowSub} id={"ShowGroupSub" + String(group.id)}
                                                         style={{display: 'none'}}/>
                                                    <img src={ArrowRight} id={"ShowGroupRight" + String(group.id)}/>
                                                </div>
                                                <div>
                                                    {group.name}
                                                </div>
                                                <div
                                                    className="project__edit--role__board--access__permissions--input__margin">
                                                    <div className={`project__edit--role__board--access__permissions--input ${group.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                        <input type="checkbox"
                                                               checked={group.systemFunctions.some(systemFunction =>
                                                                   systemFunction.accessPermissions.some(accessPermission =>
                                                                       !userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                   ))}
                                                               onClick={() => (group.systemRole != true ? changeGroup(group.id, group.systemFunctions.some(systemFunction =>
                                                                   systemFunction.accessPermissions.some(accessPermission =>
                                                                       userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                   ))) : showToast("Эта функция выдана в рамках системной роли!"))
                                                               }/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={"ShowGroupBlock" + String(group.id)}
                                                 className="project__edit--role__board--access__permissions--block"
                                                 style={{display: 'none'}}>
                                                {group.systemFunctions.map((systemFunction) => (
                                                    <div>
                                                        <div
                                                            className="project__edit--role__board--access__permissions">
                                                            <div className="project__edit--role__board--arrow"
                                                                 onClick={() => (handleViewBlocks("ShowAccessPermissionsSub" + String(systemFunction.id),
                                                                     "ShowAccessPermissionsRight" + String(systemFunction.id), "ShowAccessPermissionsBlock" + String(systemFunction.id)))}>
                                                                <img src={ArrowSub}
                                                                     id={"ShowAccessPermissionsSub" + String(systemFunction.id)}
                                                                     style={{display: 'none'}}/>
                                                                <img src={ArrowRight}
                                                                     id={"ShowAccessPermissionsRight" + String(systemFunction.id)}/>
                                                            </div>
                                                            <div
                                                                className="project__edit--role__board--access__permissions--name__function">
                                                                {systemFunction.nameFunction}
                                                            </div>
                                                            <div className="project__edit--role__board--access__permissions--input__margin">
                                                                <div className={`project__edit--role__board--access__permissions--input ${systemFunction.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                                    <input type="checkbox"
                                                                           checked={systemFunction.accessPermissions.some(accessPermission =>
                                                                               !userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                           )}
                                                                           onClick={() => (systemFunction.systemRole != true ? changeSystemFunctions(systemFunction.id, systemFunction.accessPermissions.some(accessPermission =>
                                                                               userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                           )) : showToast("Эта функция выдана в рамках системной роли!"))}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            id={"ShowAccessPermissionsBlock" + String(systemFunction.id)}
                                                            className="project__edit--role__board--access__permissions--block__item"
                                                            style={{display: 'none'}}>
                                                            {systemFunction.accessPermissions.map((accessPermission) => (
                                                                IPermissionCode[accessPermission.code as IEditPermissionCode] &&
                                                                IEditPermissionCode[accessPermission.code as IEditPermissionCode] !==
                                                                IEditPermissionCode.full ?
                                                                    <div
                                                                        className="project__edit--role__board--access__permissions--flex">
                                                                        <div
                                                                            className="project__edit--role__board--access__permissions--item">
                                                                            {IPermissionCode[accessPermission.code as IEditPermissionCode]}
                                                                        </div>
                                                                        <div
                                                                            className="project__edit--role__board--access__permissions--input__margin">
                                                                            <div className={`project__edit--role__board--access__permissions--input ${accessPermission.systemRole == true ? "project__edit--role__board--access__permissions--input__system--role" : ""}`}>
                                                                                <input type="checkbox" checked={!userRole?.roles[roleIdG].excludedPermissions.map(xx =>
                                                                                           xx.accessPermission).includes(accessPermission.id)}
                                                                                       onClick={() => (accessPermission.systemRole != true ? chengeAccessPermissions(accessPermission.id) : showToast("Эта функция выдана в рамках системной роли!"))}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                : null
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="project__edit--role__board--component__edit">
                        <div className="restrict__access--list__board--head">
                            Канбан доски:
                        </div>
                        {boardsData?.boards.map((board) => (
                            <div className="restrict__access--list__board">
                                <div className="restrict__access--list__board--desc">
                                    {board.name}
                                </div>
                                <div className="restrict__access--list__board--chek">
                                    <div className="restrict__access--list__board--chek__size">
                                        <input type="checkbox" checked={board.personRoleIds?.includes(userRole!.roles[roleIdG].personRoleId) ? true : false} onClick={() => (onCheckEvent(board.id))} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <div className="project__edit--role__board__buttons">
                    <div className="project__edit--role__board__buttons--exit">
                        <button onClick={closeFunc}>
                            Отмена
                        </button>
                    </div>
                    <div className="project__edit--role__board__buttons--add">
                        <button onClick={saveExclude}>
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectEditRoleBoard;