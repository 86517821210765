import $api from "..";
import {AxiosResponse} from "axios";
import { IRecoverPassword } from "../models/IRecoverPassword";
import { IUser } from "../models/IUser";
import {IChangePassword} from "../models/IChangePassword";

export default class AuthService {
    static async login(email: string, password: string): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>('/login', {email, password});
    }

    static async registration(email: string, password: string, name: string, surname: string, middlename: string): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>('/registration', {email, password, name, surname, middlename});
    }

    static async logout(): Promise<AxiosResponse<void>> {
        return $api.post('/logout');
    }

    static async checkActivate(email: string): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>('/chekactivate', {email});
    }

    static async fetchCurentUser(): Promise<AxiosResponse<IUser>> {
        return $api.get<IUser>('/getUser');
    }

    static async requestPasswordRecover(email: string) {
        return $api.post("/requestPasswordRecover", {email});
    }

    static async recoverPassword(body: IRecoverPassword) {
        return $api.post("/recoverPassword", body);
    }

    static async changePassword(data: IChangePassword): Promise<AxiosResponse> {
        return $api.post<AxiosResponse>('/changePassword', data);
    }
}