import React, {FC, useEffect, useState} from 'react';
import './ChooseMenu.css';
import ArrowDown from "../../../assets/arrowDown.svg"
import ArrowUp from "../../../assets/ArrowUp.svg"
import Loop from "../../../assets/search.svg"
import {ITeamMemberDisplay} from "../../../models/TeamModels";

interface Item {
    name: string;
    code: string;
}

interface ChooseMenu {
    selectedItem: Item,
    setSelectedItem:(e: any) => void;
    placeholderName: string,
    itemAbout?: string | null | undefined,
    data: Item[] | null | undefined,
    members: ITeamMemberDisplay[] | undefined
}

const ChooseMenu: FC<ChooseMenu> = ({selectedItem, setSelectedItem, placeholderName, data, itemAbout, members}) => {
    const [viewMenu, setViewMenu] = useState<boolean>(false);
    const [saveData, setSaveData] = useState<Item[] | null | undefined>();
    const [filterName, setFilterName] = useState<string>('');

    function filterItems(e: React.ChangeEvent<HTMLInputElement>) {
        setFilterName(e.target.value);

        if (e.target.value && e.target.value.length) {
            const filter = data?.filter(xx => xx.name?.toLowerCase().includes(e.target.value.toLowerCase()));
            setSaveData(filter);
        } else {
            setSaveData(data);
        }
    }

    useEffect(() => {
        setSaveData(data);
    }, []);

    return (
        <div className="select__user--component">
            <div className="select__user--component__find">
                <div className="select__user--component__person">
                    {selectedItem ?
                        <a>{selectedItem.name}</a>
                    :
                        <a>{placeholderName}</a>
                    }
                </div>
                {itemAbout?.length ?
                    <div className="select__user--component__person">
                        {selectedItem ?
                            <p>
                                {itemAbout}
                                <a className="select__user--component__person--number">
                                    {members?.filter(xx => xx.id == Number(selectedItem.code))?.length}
                                </a>
                            </p>
                        :
                            null
                        }
                    </div>
                : null}
                <div className="select__user--component__check--mark" onClick={() => (setViewMenu(!viewMenu))}>
                    {viewMenu ?
                        <img src={ArrowUp}/>
                    :
                        <img src={ArrowDown}/>
                    }
                </div>
            </div>
            <div className="select__user--component__modal">
                {viewMenu ?
                    <div>
                        <div className="select__user--component__menu--click" onClick={() => (setViewMenu(false))}></div>
                        <div className="select__user--component__menu">
                            <div className="select__user--component__menu--search">
                                <input type="search" value={filterName} onChange={filterItems} id="site-search" name="q"/>
                                <button>
                                    <img src={Loop}/>
                                </button>
                            </div>
                            <div className="select__user--component__menu--list">
                                {saveData && saveData.length ?
                                    saveData?.map(item => (
                                        <div className="select__user--component__menu--item" key={item.code} onClick={() => (setSelectedItem(item), setViewMenu(false))}>
                                            {item.name}
                                        </div>
                                    ))
                                :
                                    <div className="select__user--component__menu--item__none">Не найдено элементов</div>
                                }
                            </div>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    );
};

export default ChooseMenu;