import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import "../styles/header.pc.css";

import Settings from "../../assets/settings.svg";
import Notification from "../../assets/notification.svg";
import Avatar from "../Shared/Avatar/Avatar";
import "../Header/Header.css";
import { Link, useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { AppContext } from "../../App";
import { IBreadCrumb } from "../../models/IBreadCrumb";
import { MenuItem } from "primereact/menuitem";
import SettingsPopup from "../PersonalSettings/SettingsPopup/SettingsPopup";
import PersonalSettings from "../PersonalSettings/PersonalSettings/PersonalSettings";
import PasswordChange from "../PersonalSettings/PasswordChangePopup/PasswordChange";
import { getFileUrlFromId } from "../../helpers/getFileUrlFromId";

const Header = () => {
    const { store } = useContext(Context);
    const { breadCrumb } = useContext(AppContext);
    const navigate = useNavigate();
    const [showSettingsPopup, setShowSettingsPopup] = useState<boolean>(false);
    const [showSettingsPopupLock, setShowSettingsPopupLock] =
        useState<boolean>(false);
    const [showSettingsMenu, setShowSettingsMenu] = useState<boolean>(false);
    const [showPasswordChangeMenu, setPasswordChangeMenu] = useState<boolean>(false);

    const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumb[]>([]);

    useEffect(() => {
        setBreadCrumbs(
            breadCrumb.map((b: IBreadCrumb) => {
                const bread: MenuItem = {
                    label: b.label,
                    url: b.url,
                    template: <Link to={b.url}>{b.label}</Link>,
                };
                return bread;
            })
        );
    }, [breadCrumb]);

    function goToSettings() {
        navigate("/system/settings");
    }

    const closeSettingsPopup = () => {
        setShowSettingsPopupLock(true);
        // Не мы такие, жизнь такая...
        window.setTimeout(() => {
            setShowSettingsPopupLock(false);
        }, 300);
        setShowSettingsPopup(false);
    };

    return (
        <div className="header-component">
            <div className="headName">
                <BreadCrumb model={breadCrumbs} />
            </div>
            <div id="right-control-panel">
                <button className="button_reset_style" onClick={goToSettings}>
                    <img className="button_settings" src={Settings} alt="" />
                </button>
                <button className="button_reset_style">
                    <div className="header_icon_notification">
                        <img
                            className="button_notification"
                            src={Notification}
                            alt=""
                        />
                    </div>
                </button>
                <div
                    style={{ cursor: "pointer", display: "flex", alignItems: "center"}}
                    onClick={() =>
                        showSettingsPopupLock
                            ? null
                            : setShowSettingsPopup(!showSettingsPopup)
                    }
                >
                    <div className="header__component-text">
                        <a className="a_header_name_16">
                            {store.user.name} {store.user.surname}
                        </a>
                    </div>
                    <Avatar url={getFileUrlFromId(store.user.photoId)} size="xl"/>
                </div>
                {showSettingsPopup ? (
                    <div className="header-settings-popup-container">
                        <SettingsPopup
                            onClose={closeSettingsPopup}
                            onSettingsClick={() => {
                                setShowSettingsMenu(true);
                                setShowSettingsPopup(false);
                            }}
                            onPasswordChangeClick={()=> { //Добавил такой-же попап для смены пароля
                                setPasswordChangeMenu(true);
                                setShowSettingsPopup(false);
                            }}
                        />
                    </div>
                ) : null}
                {showSettingsMenu ? (
                    <div className="header-settings-popup-container">
                        <PersonalSettings
                            onClose={() => setShowSettingsMenu(false)}
                        />
                    </div>
                ) : null}
                {showPasswordChangeMenu ? (
                    <div className="header-settings-popup-container">
                        <PasswordChange
                            onClose={() => setPasswordChangeMenu(false)}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default observer(Header);
