import $api from "..";
import { AxiosResponse } from "axios";
import {
    IGetTasksDisplayData,
    ITasksDisplay,
    ITasksDisplayFilters,
} from "../models/TaskModels";
import { IMoveTaskBoardData, IMoveTaskData, IMoveTasksData } from "../models/IMoveTaskData";
import { IDeleteTasks } from "../models/IDeleteTasks";
import { ITaskGeneralInfo } from "../models/ITask";
import { ICreateTegTask } from "../models/ICreateTegTask";
import { IChatMessage } from "../models/IChatMessage";
import { ITag, ITaskFormDisplay } from "../models/ITaskFormDisplay";
import { ITaskCreate } from "../models/ITaskCreate";
import { ITaskEdit } from "../models/ITaskEdit";
import { IUpdateTegTask } from "../models/IUpdateTegTask";
import { IUpdateTypeTask } from "../models/IUpdateTypeTask";
import { IGetAllTaskPerson } from "../models/IAllTaskPerson";
import { IGetTaskRequest } from "../models/IGetTaskRequest";
import {ITypeAndPriority} from "../models/ITypeAndPriority";

export default class TaskService {
    static async getProjectTasksDisplayData(
        body: IGetTasksDisplayData
    ): Promise<AxiosResponse<ITasksDisplay>> {
        return $api.post<ITasksDisplay>(`Project/getTasks`, body);
    }

    static async getAllTaskPerson(
        taskId: number
    ): Promise<AxiosResponse<IGetAllTaskPerson>> {
        return $api.get<IGetAllTaskPerson>(
            `Task/getAllTaskPerson?taskId=${taskId}`
        );
    }

    static async getTypeAndPriority(
    ): Promise<AxiosResponse<ITypeAndPriority>> {
        return $api.get<ITypeAndPriority>(
            `Project/getTypeAndPriority`
        );
    }

    static async updateTaskType(
        body: IUpdateTypeTask
    ): Promise<AxiosResponse<any>> {
        return $api.post<any>(`Task/updateTaskType`, body);
    }

    static async updateTaskPriority(
        body: IUpdateTegTask
    ): Promise<AxiosResponse<any>> {
        return $api.post<any>(`Task/updateTaskPriority`, body);
    }

    static async deleteMessage(
        idMessage: number
    ): Promise<AxiosResponse<IGetAllTaskPerson>> {
        return $api.get<IGetAllTaskPerson>(
            `Task/deleteMessage?idMessage=${idMessage}`
        );
    }

    static async sendMessage(body: {
        message: string;
        taskId: number;
    }): Promise<AxiosResponse<boolean>> {
        return $api.post<boolean>(`Project/sendMessage`, body);
    }

    static async saveEditMessage(body: {
        message: string;
        messageId: number;
    }): Promise<AxiosResponse<boolean>> {
        return $api.post<boolean>(`Project/saveEditMessage`, body);
    }

    static async getChatMessage(
        taskId: number
    ): Promise<AxiosResponse<IChatMessage>> {
        return $api.get<IChatMessage>(
            `Project/getChatMessage?taskId=${taskId}`
        );
    }

    static async getTask(
        reqBody: IGetTaskRequest
    ): Promise<AxiosResponse<ITaskGeneralInfo>> {
        return $api.post<ITaskGeneralInfo>(`Project/getTaskInfo`, reqBody);
    }

    static async getTaskForm(
        taskId: number
    ): Promise<AxiosResponse<ITaskFormDisplay>> {
        return $api.get<ITaskFormDisplay>(`Project/getTask?taskId=${taskId}`);
    }

    static async addTegTask(
        body: ICreateTegTask
    ): Promise<AxiosResponse<number>> {
        return $api.post<number>(`teg/addTegTaskInfo`, body);
    }

    static async getTegs(body: {
        taskId: number | undefined;
        projectId: number | undefined;
        userId: number;
    }): Promise<AxiosResponse<ITag[]>> {
        return $api.post<ITag[]>(`teg/getTegs`, body);
    }

    static async getProjectTasksFilters(
        projectId: number
    ): Promise<AxiosResponse<ITasksDisplayFilters>> {
        return $api.get<ITasksDisplayFilters>(
            `Project/taskDisplayFilters?projectId=${projectId}`
        );
    }

    static async moveTask(body: IMoveTaskData): Promise<void> {
        return $api.post("Project/dashBoard/moveTask", body);
    }

    static async moveTaskBoard(body: IMoveTaskBoardData): Promise<void> {
        return $api.post("/Task/moveTaskOutsideBoard", body);
    }

    static async moveTasks(body: IMoveTasksData): Promise<void> {
        return $api.post("/Project/dashBoard/bulkMoveTasks", body);
    }

    static async deleteTasks(body: IDeleteTasks): Promise<void> {
        return $api.post("Project/deleteTasks", body);
    }

    static async createTask(body: ITaskCreate): Promise<AxiosResponse<ITaskFormDisplay>> {
        return $api.post("Project/addTask", body);
    }
    static async editTask(body: ITaskEdit): Promise<void> {
        return $api.post("Project/editTask", body);
    }

    static async addTagsInTasks(body: { tags: ITag[], taskId: number }): Promise<void> {
        return $api.post("Task/addTagsInTasks", body);
    }
}
