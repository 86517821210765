import React from 'react';
import './MisceleniousStyles.css'

const TaskColumn = (params: any) => {
    return (
        <div className='task-column-widget nonselectable' style={{backgroundColor: params.color, textWrap: "nowrap"}}>
            {params.name}
        </div>
    );
}
export default TaskColumn;
