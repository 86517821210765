import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "../../styles/dashboard.pc.css";
import "./columnsStyle.css";
import { BoardContext } from "../../../pages/Board/Board";
import BoardColumn from "../BoardColumn/BoardColumn";
import { AppContext } from "../../../App";
import { useParams } from "react-router-dom";
import {IHasPermission} from "../../../models/IChekRole";

export interface IBoardColumnsProps {
    userAccess: IHasPermission[]
}

const BoardColumns: React.FC<IBoardColumnsProps> = ({userAccess}) => {
    const { boardId } = useParams();
    const { filteredColumns } = useContext(BoardContext);
    const { currentZoom, setCurrentZoom, MAX_ZOOM, MIN_ZOOM, boardScrollPosition, setBoardScrollPosition } =
        useContext(AppContext);

    const sliderRef = useRef<HTMLDivElement>(null);
    const [isMoving, setIsMoving] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const saveScrollPosition = () => {
        if (sliderRef.current) {
            setBoardScrollPosition([Number(boardId), sliderRef.current.scrollLeft]);
        }
    };

    useEffect(() => {

    }, [userAccess]);

    useEffect(() => {
        const slider = sliderRef.current;

        if (slider) {
            slider.addEventListener("scroll", saveScrollPosition);
            if (boardScrollPosition !== undefined && Number(boardId) === boardScrollPosition[0]) {
                slider.scrollLeft = boardScrollPosition[1];
                setScrollLeft(boardScrollPosition[1]);
            }
        }

        return () => {
            if (slider) {
                slider.removeEventListener("scroll", saveScrollPosition);
            }
        };
    }, [boardId, filteredColumns]);

    const handleMouseDown = (e: React.MouseEvent) => {
        if (e.button !== 2 || !sliderRef.current) return; // Проверка на ПКМ и null
        setIsMoving(true);
        setStartX(e.pageX - sliderRef.current.offsetLeft);
        setScrollLeft(sliderRef.current.scrollLeft);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isMoving || !sliderRef.current) return;
        e.preventDefault();
        const x = e.pageX - sliderRef.current.offsetLeft;
        const walk = (x - startX); // Коэффициент прокрутки
        sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsMoving(false);
    };

    const handleWheelEvent = (e: any) => {
        if (e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();

            setCurrentZoom((z: any) => {
                const newZoom = e.deltaY < 0 ? z + 0.05 : z - 0.05;
                return Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, newZoom));
            });

        }
    };

    function checkOverflowAndResize() {
        const blockElement = document.getElementById('dash__board--elem__list--zoomable__block');
        if (blockElement!.scrollWidth > blockElement!.clientWidth) {
            blockElement!.style.height = '';
        } else {
            blockElement!.style.height = 'calc(100vw - 310px)';
        }
    }

    useEffect(() => {
        //Делается через document потому что необходима конструкция passive: false
        //доступная только так. Отвязка ивента нужна, иначе не работает, особенности react
        const element: any = document.querySelector(".zoomable__block");
        if (element) {
            element.addEventListener("wheel", handleWheelEvent, {
                passive: false,
            });
        }
        return () => element.removeEventListener("wheel", handleWheelEvent);

    }, [currentZoom]);
    
    return (
        <div className="dash__board--form" >
            <div className="dash__board--form-column " >
                <div id={"dash__board--elem__list--zoomable__block"} className={`dash__board--elem__list slider zoomable__block ${isMoving ? "slider-hand" : ""}`}
                    ref={sliderRef} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp}
                    onMouseUp={handleMouseUp} onContextMenu={(e) => e.preventDefault()}
                     style={{ zoom: "" + currentZoom}}
                >
                    {filteredColumns.map((column) => (
                        <BoardColumn key={column.id} column={column} userAccess={userAccess}/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default observer(BoardColumns);
