import React, {createRef, FC, useContext, useEffect, useLayoutEffect, useState} from 'react';
import "./AddEditRole.css"
import {
    IGetOneRole,
    IRoleAvailableFunctions,
    IRoleAvailablePermissions, IRoleFunctionGroups, IRolePermissions,
    IRoleSystemFunction
} from '../../../models/IGetOneRole';
import Cross from "../../../assets/cancelGreey.svg"
import SystemSettings from "../../../pages/SystemSettings/SystemSettings";
import ArrowSub from "../../../assets/arrowDown.svg"
import ArrowRight from "../../../assets/ArrowRight.svg"
import {IEditRole} from "../../../models/IEditRole";
import {Context} from "../../../index";
import rolesSystem from "../RolesSystem/RolesSystem";
import {ICreateRole} from "../../../models/ICreateRole";

interface IAddEditWidget {
    mode: "create" | "edit",
    role: IGetOneRole | undefined
    closeFunc: () => void;
    getData: () => void;
}

enum IPermissionCode {
    "create" = "Создание",
    "view" = "Просмотр",
    "delete" = "Удаление",
    "edit" = "Редактирование",
    "full" = "Все"
}

enum IEditPermissionCode {
    "create" = "create",
    "view" = "view",
    "delete" = "delete",
    "edit" = "edit",
    "full" = "full"
}

const AddEditRole: FC<IAddEditWidget> = ({role, mode, closeFunc, getData}) => {
    const [dataRole, setDataRole] = useState<IGetOneRole | undefined>();
    const [nameValue, setNameValue] = useState<string | undefined>("");
    const [saveData, setSaveData] = useState<IGetOneRole | undefined>();
    const [projectRole, setProjectRole] = useState<boolean>();
    const {store} = useContext(Context);

    useEffect(() => {
        setDataRole(role);
        setNameValue(role?.roleName);
        setSaveData(role);
        setProjectRole(role?.requiredProject === 1);
    }, [role]);

    function viewBlock(idView: string, imageOne: string, imageTwo: string) {
        const element = document.getElementById(idView);
        const elementOne = document.getElementById(imageOne);
        const elementTwo = document.getElementById(imageTwo);

        if (element) {
            if (element.style.display === 'none') {
                element.style.display = 'block';
            } else {
                element.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${idView} не найден.`);
        }

        if (elementOne) {
            if (elementOne.style.display === 'none') {
                elementOne.style.display = 'block';
            } else {
                elementOne.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${elementOne} не найден.`);
        }

        if (elementTwo) {
            if (elementTwo.style.display === 'none') {
                elementTwo.style.display = 'block';
            } else {
                elementTwo.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${elementTwo} не найден.`);
        }
    };

    function getMaxId(xx: IRoleSystemFunction) {
        const maxId = xx.availablePermissions.reduce((maxPermission, currentPermission) => {
            return currentPermission.id > maxPermission.id ? currentPermission : maxPermission;
        }, xx.availablePermissions[0]).id
        return maxId
    };

    function changeSystemFunction(functionRole: number, availableCode: string, group: number) {
        if (dataRole) {
            let checkSystemFunctions = dataRole.systemFunctions.find((xx) => xx.id === functionRole);
            if (checkSystemFunctions) {

                let newSystemFunctions = dataRole.systemFunctions.map((xx) => {
                    if (xx.id === functionRole) {
                        if (xx.permissions.find((perm) => perm.code === availableCode)) {
                            xx.permissions = xx.permissions.filter((perm) => perm.code !== availableCode);
                            console.log("if", xx.permissions.map(gg => gg.code))
                        } else if (xx.permissions.find((perm) => perm.code === IEditPermissionCode.full)) {
                            xx.permissions = xx.permissions.filter((perm) => perm.code !== IEditPermissionCode.full);
                            xx.permissions = [...xx.permissions, ...xx.availablePermissions.map((yy) => ({
                                code: yy.code as IEditPermissionCode
                            }))];
                            xx.permissions = xx.permissions.filter((perm) => perm.code !== availableCode && perm.code !== IEditPermissionCode.full);
                            console.log("else if", xx.permissions.map(gg => gg.code))
                        } else {
                            xx.permissions = [...xx.permissions, {code: availableCode as IEditPermissionCode}];
                            // if (xx.permissions.length == xx.availablePermissions.length - 1 && xx.availablePermissions.map(xx => xx.code).includes(IEditPermissionCode.full)) {
                            //     xx.permissions = [];
                            //     xx.permissions = [...xx.permissions, {code: IEditPermissionCode.full}];
                            // }
                            console.log("else", xx.permissions.map(gg => gg.code))
                        }
                    }
                    return xx;
                });
                console.log(newSystemFunctions)
                const updatedRole = {...dataRole, systemFunctions: newSystemFunctions};

                let getNewSystemFunction = updatedRole.systemFunctions.find((xx) => xx.id === functionRole)?.permissions.length === 0;

                if (getNewSystemFunction) {
                    updatedRole.systemFunctions = updatedRole.systemFunctions.filter(xx => xx.id !== functionRole);
                }
                setDataRole(updatedRole);
            } else {
                let groupCheckAvailableFunctions = dataRole?.functionGroups.find((xx) => xx.id == group);
                let checkAvailableFunctions = groupCheckAvailableFunctions?.availableFunctions.find((xx) => xx.id === functionRole);
                if (checkAvailableFunctions) {
                    const newSystemFunction: IRoleSystemFunction = {
                        id: checkAvailableFunctions.id,
                        nameFunction: checkAvailableFunctions.nameFunction,
                        functionCode: checkAvailableFunctions.functionCode,
                        permissions: [{code: availableCode as IEditPermissionCode}],
                        availablePermissions: checkAvailableFunctions.availablePermissions,
                    };

                    const updatedSystemFunctions = [...dataRole.systemFunctions, newSystemFunction];
                    const updatedRole = {...dataRole, systemFunctions: updatedSystemFunctions};

                    setDataRole(updatedRole);
                }
            }
        }
    };

    function checkAllGroupSystemFunction(checked: boolean, group: IRoleFunctionGroups) {
        if (checked == true) {
            let functionGroup = dataRole?.functionGroups.find((xx) => xx.id == group.id)
            if (functionGroup) {
                let newSystemFunctions: IRoleSystemFunction[] = [];
                functionGroup.availableFunctions.forEach((xx) => {
                    newSystemFunctions.push({
                        id: xx.id,
                        nameFunction: xx.nameFunction,
                        functionCode: xx.functionCode,
                        permissions:  xx.availablePermissions,
                        // xx.availablePermissions.find((perm) => perm.code === IEditPermissionCode.full)
                        //                             ? [xx.availablePermissions.find((perm) => perm.code === IEditPermissionCode.full) as IRolePermissions]
                        //                             :
                        availablePermissions: xx.availablePermissions
                    })
                })
                newSystemFunctions = newSystemFunctions.filter(xx => !dataRole?.systemFunctions.map(yy => yy.id).includes(xx.id));
                const updatedSystemFunctions = [...dataRole!.systemFunctions, newSystemFunctions];
                const updatedRole = {...dataRole!, id: dataRole!.id || 0, systemFunctions: updatedSystemFunctions.flat() as IRoleSystemFunction[]};

                if (dataRole) {
                    setDataRole(updatedRole);
                }
            }
        } else {
            let functionGroup = dataRole?.functionGroups.find((xx) => xx.id == group.id)
            if (functionGroup) {
                let newSystemFunctions = dataRole?.systemFunctions.filter((xx) => !functionGroup!.availableFunctions.map(yy => yy.id).includes(xx.id))
                const updatedRole = {...dataRole!, id: dataRole!.id || 0, systemFunctions: newSystemFunctions?.flat() as IRoleSystemFunction[]};
                setDataRole(updatedRole);
            }
        }
        // chekGlobalObjectPrewiew(group.id);
    };

    function checkAllSystemFunction(currentFunc: IRoleAvailableFunctions, checked: boolean, group: number) {
        if (checked == false) {
            if (dataRole?.systemFunctions.find((yy) => yy.id == currentFunc.id)) {
                let newSystemFunctions = dataRole.systemFunctions.map((xx) => {
                    if (xx.id === currentFunc.id) {
                        // if (xx.availablePermissions.find((yy) => yy.code === IEditPermissionCode.full)) {
                        //     xx.permissions = [];
                        //     xx.permissions.push({code: IEditPermissionCode.full})
                        // } else {
                            xx.permissions = [];
                            xx.permissions = [...xx.permissions, ...xx.availablePermissions.map((zz) => ({
                                code: zz.code as IEditPermissionCode
                            }))];
                        // }
                    }
                    return xx;
                });

                const updatedRole = {...dataRole, systemFunctions: newSystemFunctions};
                setDataRole(updatedRole);
            } else {
                if (dataRole) {
                    let groupCheckAvailableFunctions = dataRole?.functionGroups.find((xx) => xx.id == group);
                    let checkAvailableFunctions = groupCheckAvailableFunctions?.availableFunctions.find((xx) => xx.id === currentFunc.id);
                    if (checkAvailableFunctions) {
                        // if (checkAvailableFunctions.availablePermissions.find((yy) => yy.code === IEditPermissionCode.full)) {
                        //     const newSystemFunction: IRoleSystemFunction = {
                        //         id: checkAvailableFunctions.id,
                        //         nameFunction: checkAvailableFunctions.nameFunction,
                        //         functionCode: checkAvailableFunctions.functionCode,
                        //         permissions: [{code: IEditPermissionCode.full}],
                        //         availablePermissions: checkAvailableFunctions.availablePermissions,
                        //     };
                        //     const updatedSystemFunctions = [...dataRole.systemFunctions, newSystemFunction];
                        //     const updatedRole = {...dataRole, systemFunctions: updatedSystemFunctions};
                        //     setDataRole(updatedRole);
                        // } else {
                            let newSystemFunc = checkAvailableFunctions.availablePermissions.map((zz) => ({
                                code: zz.code as IEditPermissionCode
                            }));

                            const newSystemFunction: IRoleSystemFunction = {
                                id: checkAvailableFunctions.id,
                                nameFunction: checkAvailableFunctions.nameFunction,
                                functionCode: checkAvailableFunctions.functionCode,
                                permissions: newSystemFunc,
                                availablePermissions: checkAvailableFunctions.availablePermissions,
                            };

                            const updatedSystemFunctions = [...dataRole.systemFunctions, newSystemFunction];
                            const updatedRole = {...dataRole, systemFunctions: updatedSystemFunctions};
                            setDataRole(updatedRole);
                        // }
                    }
                }
            }
        } else {
            if (dataRole) {
                let newSystemFunctions = dataRole.systemFunctions.filter((xx) => xx.id !== currentFunc.id)
                const updatedRole = {...dataRole, systemFunctions: newSystemFunctions};
                setDataRole(updatedRole);
            }
        }
        // chekGlobalObjectPrewiew(group);
    };

    function chekIn(perm: IRoleAvailableFunctions, permission: IRoleAvailablePermissions): boolean {
        let currentSystemFunc = dataRole?.systemFunctions.find(zz => zz.id == perm.id);
        if (currentSystemFunc) {
            let currentSystemPerm = currentSystemFunc.permissions.map(perm => perm.code);
            if (currentSystemPerm && currentSystemPerm.find((xx) => xx.includes(permission.code) || xx.includes(IEditPermissionCode.full))) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    function getAvailablePermissions(perm: IRoleAvailableFunctions, group: number) {
        return (
            <div id={"SystemSettings" + String(perm.id)} style={{display: 'none'}} className='add__edit--role__component--name__function--permissions__block'>
                {perm.availablePermissions.map((yy) => {
                    if (yy.code !== IEditPermissionCode.full) {
                        return (
                            <div key={"Permission" + yy.code}
                                 className='add__edit--role__component--name__function--permissions__block--margin'>
                                <div>
                                    {IPermissionCode[yy?.code]}
                                </div>
                                <div className='add__edit--role__component--name__function--chek'>
                                    <input type='checkbox'
                                           onClick={() => (changeSystemFunction(perm.id, yy.code, group))}
                                           checked={chekIn(perm, yy)}/>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        )
    };

    function onChangeName(event: any) {
        setNameValue(event.target.value);
    };

    function onClickEdit() {
        let newEdit = dataRole;
        if (newEdit) {
            //null - если поле пустое
            //undefined - поле не изменялось
            //some - отправляем для перезаписи
            let newEditRole: IEditRole = {
                id: newEdit.id,
                roleName: nameValue != undefined ? nameValue : newEdit.roleName,
                requiredProject: projectRole ? 1 : 0,
                systemFunctions:
                    newEdit.systemFunctions.length > 0
                        ? saveData?.systemFunctions !== newEdit.systemFunctions
                            ? newEdit.systemFunctions.map((xx) => ({
                                id: xx.id,
                                permissions: xx.permissions.map((perm) => ({
                                    code: perm.code,
                                })),
                            }))
                            : undefined
                        : null,
            };

            (async () => {
                try {
                    await store.editRole(newEditRole);
                    getData();
                } catch (error) {
                    console.log(error);
                }
            })();
            closeFunc();
        }
    }

    function onClickCreate() {
        let newAdd = dataRole;
        if (newAdd) {
            let newAddRole: ICreateRole = {
                roleName: nameValue ? nameValue : "",
                requiredProject: projectRole ? 1 : 0,
                systemFunctions: newAdd.systemFunctions.map((xx) => {
                    return {
                        id: xx.id,
                        permissions: xx.permissions
                    };
                })
            };

            (async () => {
                try {
                    await store.createRole(newAddRole);
                    getData();
                } catch (error) {
                    console.log(error);
                }
            })();
            closeFunc();
        }
    }

    function onClickCansel() {
        setDataRole(saveData);
        closeFunc();
    }

    function chekGlobalObjectPrewiew(idGroup: number){
        let data = dataRole;
        let chekFunc = data?.functionGroups.find(yz => yz.id == idGroup)!.availableFunctions.map((yy) =>
            data?.systemFunctions.map((zz) => zz.id).some((id) => id === yy.id)
        );
        if (chekFunc) {
            if (chekFunc.find((zy) => zy == true)) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    return (
        <div id="DSKGFJKSDJFLJSDLF" className='add__edit--role__component'>
            <div className='add__edit--role__component--header'>
                <div>
                    {mode == "create" ? "Новая роль" : "Редактирование роли"}
                </div>
                <div className='add__edit--role__component--header__img'>
                    <img src={Cross} onClick={closeFunc}/>
                </div>
            </div>
            <div className='add__edit--role__component--name__role--block'>
                <div className='add__edit--role__component--name__role'>
                    <div className='add__edit--role__component--name__role--desc'>
                        Название роли
                    </div>
                    <div className='add__edit--role__component--name__role--input'>
                        <input type='text' value={nameValue} onChange={onChangeName}
                               placeholder='например Администратор'/>
                    </div>
                </div>
                <div className='add__edit--role__component--name__role--project__role'>
                    <div className='add__edit--role__component--block__role--project__role--text'>
                        Проектная роль
                    </div>
                    <div className='add__edit--role__component--name__role--desc__chek'>
                        <input type="checkbox" checked={projectRole} onClick={() => (setProjectRole(!projectRole))}/>
                    </div>
                </div>
                <div className='add__edit--role__component--name__role--desc'>
                    Возможность роли
                </div>
                <div className='add__edit--role__component--name__role--scroll'>
                    <div className='add__edit--role__component--name__role--data__role'>
                        {dataRole && dataRole?.functionGroups.map((group) => (
                            !(group.id === 4 && projectRole) ?
                            <div>
                                <div className='add__edit--role__component--name__group'>
                                    <button
                                        onClick={() => (viewBlock("Group" + String(group.id), "SystemGroupArrowSub" + String(group.id), "SystemGroupArrowRight" + String(group.id)))}
                                        className='add__edit--role__component--button__show--more'>
                                        <img src={ArrowSub} id={"SystemGroupArrowSub" + String(group.id)}
                                             style={{display: 'none'}}/>
                                        <img src={ArrowSub} id={"SystemGroupArrowRight" + String(group.id)} style={{transform: "rotate(-90deg)"}}/>
                                    </button>
                                    <div>
                                        {group.nameGroup}
                                    </div>
                                    <div id={"GroupInputDiv" + String(group.id)} className='add__edit--role__component--name__function--chek'>
                                        <input id={"GroupInput" + String(group.id)} type='checkbox' checked={chekGlobalObjectPrewiew(group.id)} onClick={(event: any) => event.target && checkAllGroupSystemFunction(event.target.checked, group)}/>
                                    </div>
                                </div>
                                <div id={"Group" + group.id} style={{display: 'none'}}>
                                    {group && group?.availableFunctions.map((xx) => (
                                        <div id={"AvailableFunctions" + xx.id}>
                                            <div className='add__edit--role__component--name__function'>
                                                <button
                                                    onClick={() => (viewBlock("SystemSettings" + String(xx.id), "SystemArrowSub" + String(xx.id), "SystemArrowRight" + String(xx.id)))}
                                                    className='add__edit--role__component--button__show--more'>
                                                    <img src={ArrowSub} id={"SystemArrowSub" + String(xx.id)}
                                                         style={{display: 'none'}}/>
                                                    <img src={ArrowSub} id={"SystemArrowRight" + String(xx.id)} style={{transform: "rotate(-90deg)"}}/>
                                                </button>
                                                <div className='add__edit--role__component--name__function--block'>
                                                    {xx.nameFunction}
                                                </div>
                                                <div className='add__edit--role__component--name__function--chek'>
                                                    <input type='checkbox'
                                                           onClick={() => (checkAllSystemFunction(xx, dataRole?.systemFunctions.some(yy => yy.id === xx.id), group.id))}
                                                           checked={dataRole.systemFunctions.some(yy => yy.id === xx.id)}/>
                                                </div>
                                            </div>
                                            {getAvailablePermissions(xx, group.id)}
                                        </div>
                                    ))}
                                </div>
                            </div> : <div> </div>
                        ))}
                    </div>
                </div>
                <div className='add__edit--role__component--buttons'>
                    <button onClick={onClickCansel} className='add__edit--role__component--buttons__cansel'>
                        Отмена
                    </button>
                    <button onClick={mode == "create" ? onClickCreate : onClickEdit}
                            className='add__edit--role__component--buttons__create'>
                        {mode == "create" ? "Создать" : "Сохранить"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddEditRole;