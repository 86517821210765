import React, {FC, useContext, useEffect, useState} from 'react';
import "./ProjectAddPersonRole.css"
import ExitCross from "../../../assets/cancelGreey.svg";
import useOutsideAlerter from "../../../customHooks/useOutsideAlert";
import ChooseItem from "../chooseUser/ChooseItem";
import SaveInputValue from "../../../assets/save_input_value.svg";
import BtnCancel from "../../buttons/CancelButton/CancelButton";
import {useUpdateEffect} from "primereact/hooks";
import {Context} from "../../../index";
import ProjectEditRoleBoard from "../ProjectEditRoleBoard/ProjectEditRoleBoard";
import {IExcludePermissionFunction} from "../../../models/IExcludePermissionFunction";
import ChooseMenu from "../ChooseMenu/ChooseMenu";
import {ITeamMemberDisplay} from "../../../models/TeamModels";

interface Item {
    name: string;
    code: string;
    status?: string;
}

interface ItemPersonRole {
    name: string;
    code: string;
    personRoleId: number;
}

interface IProjectAddPersonRole {
    closeFunc: () => void;
    value: string | undefined,
    setValue: (e: any) => void,
    open: boolean,
    save: (e: any) => void,
    typeInput: string,
    selectedUser?: Item | null,
    setSelectedUser?: (e: any) => void,
    selectedRole?: Item | null,
    setSelectedRole?: (e: any) => void,
    persons?: Item[] | null,
    roles?: Item[] | null,
    roleEdit?: IExcludePermissionFunction | undefined
    projectId: number | null,
    modeCreateP: boolean,
    personId: ItemPersonRole | null,
    members: ITeamMemberDisplay[] | undefined
}


const ProjectAddPersonRole: FC<IProjectAddPersonRole> = ({personId, modeCreateP, closeFunc, value, setValue, open,
    save, typeInput, selectedUser, setSelectedUser,
    selectedRole, setSelectedRole, persons, roles, roleEdit, projectId, members}) => {
    const [selectedRoleStep, setSelectedRoleStep] = useState<Item | null>(null);
    const [selectedUserStep, setSelectedUserStep] = useState<ItemPersonRole | null>(null);
    const [modeCreate, setModeCreate] = useState<boolean>(false);
    const [modeError, setModeError] = useState<boolean>(false);
    const { store } = useContext(Context);

    function closeModal() {
        closeFunc();
    }

    function savePersonRole(e: any) {
        save(e);
    }

    useEffect(() => {
        setSelectedUser!(selectedUserStep);
        let findPerson = members?.find(person => person.id == Number(selectedUserStep?.code));
        if (selectedRoleStep != undefined && findPerson?.roleId == Number(selectedRoleStep.code)) {
            setModeError(true);
        } else {
            setModeError(false)
        }
    }, [selectedUserStep]);

    useEffect(() => {
        setSelectedRole!(selectedRoleStep);
        if (selectedUserStep != undefined) {
            let findPerson = members?.find(person => person.id == Number(selectedUserStep?.code));
            if (selectedRoleStep != undefined && findPerson?.roleId == Number(selectedRoleStep.code)) {
                setModeError(true);
            } else {
                setModeError(false)
            }
        }
    }, [selectedRoleStep]);

    useEffect(() => {

    }, [roleEdit]);

    return (
        <div className="project__add--person__role--modal">
            <div className="project__add--person__role--block">
                {modeCreate == false ?
                    <div>
                        <div className="project__add--person__role--block__header">
                            <div className="project__add--person__role--block__header--name">
                                Добавить пользователя
                            </div>
                            <div className="project__add--person__role--block__header--exit">
                                <button onClick={() => {closeModal()}}>
                                    <img src={ExitCross}/>
                                </button>
                            </div>
                        </div>
                        <div className="project__add--person__role--block__settings">
                            <div className="project__add--person__role--block__settings--name__item">
                                Пользователь
                            </div>
                            <div className="project__add--person__role--block__settings--item">
                                <ChooseMenu selectedItem={selectedUser!} setSelectedItem={setSelectedUserStep!} placeholderName='Выберите пользователя' itemAbout="Количество ролей в проекте " data={persons} members={members}/>
                            </div>
                            <div className="project__add--person__role--block__settings--name__item">
                                Роль
                            </div>
                            <div className="project__add--person__role--block__settings--item">
                                <ChooseMenu selectedItem={selectedRoleStep!} setSelectedItem={setSelectedRoleStep!} placeholderName='Выберите роль' data={roles} members={members}/>
                            </div>
                            {modeError ?
                                <div className="project__add--person__role--block__buttons--errors">
                                    Пользователь с такой ролью уже добавлен в проект!
                                </div>
                            :
                                <div className="project__add--person__role--block__buttons--errors">

                                </div>
                            }
                        </div>
                        <div className="project__add--person__role--block__buttons">
                            <div className="project__add--person__role--block__buttons--exit">
                                <button onClick={closeFunc}>
                                    Отмена
                                </button>
                            </div>
                            <div className="project__add--person__role--block__buttons--add">
                                <button style={modeError ? {backgroundColor: "rgba(107,114,128,0.3)"} : {}} onClick={(e: any) => { if (!modeError) { savePersonRole(e) } }}>
                                    Создать
                                </button>
                            </div>
                        </div>
                    </div>
                :
                    <div className="project__add--person__role--block__edit--role">
                        <ProjectEditRoleBoard personId={personId} closeFunc={closeFunc} roleEdit={roleEdit} projectId={projectId}/>
                    </div>
                }
            </div>
        </div>
    );
};

export default ProjectAddPersonRole;