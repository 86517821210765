import { FC, useEffect, useRef, useState } from "react";
import { IMonitorTaskDisplay } from "../../../models/IUserMonitorTaskFullInfo";
import { initials } from "../../../helpers/Inicials";
import { dateToNiceString } from "../../../helpers/dateToNiceString";
import MarkdownPreview from "../../Shared/MarkdownPreview/MarkdownPreview";
import ExitMark from "../../../assets/ButtonIcons/user-monitor-exit-button.svg";

interface ITaskDescriptionProps {
    task: IMonitorTaskDisplay;
    setSelectedTaskId: (taskId: number | undefined) => void;
}

const TaskDescription: FC<ITaskDescriptionProps> = ({
    task,
    setSelectedTaskId,
}) => {
    const [showFullComment, setShowFullComment] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showToggleDescription, setShowToggleDescription] = useState(false);
    const [showToggleComment, setShowToggleComment] = useState(false);
    const commentRef = useRef<HTMLDivElement | null>(null);
    const descriptionRef = useRef<HTMLDivElement | null>(null);

    const OVERFLOW_HEIGHT = 230;

    useEffect(() => {
        const checkCommentsHeight = () => {
            if (commentRef.current)
                setShowToggleComment(
                    commentRef.current.clientHeight > OVERFLOW_HEIGHT
                );

            if (descriptionRef.current)
                setShowToggleDescription(
                    descriptionRef.current.clientHeight > OVERFLOW_HEIGHT
                );
        };

        checkCommentsHeight();
        window.addEventListener("resize", checkCommentsHeight);

        return () => {
            window.removeEventListener("resize", checkCommentsHeight);
        };
    }, [task]);

    return (
        <div>
            <div
                className="exit-button"
                onClick={() => {
                    setSelectedTaskId(undefined);
                }}
            >
                <img src={ExitMark} alt="Закрыть задачу" />
            </div>
            <div className="right-bar-top-header break-word">{task.name}</div>
            <div className="user-monitor-right-bar-block">
                <div
                    ref={descriptionRef}
                    id="full-description-foldable-field"
                    className={`block-text ${
                        showToggleDescription
                            ? showFullDescription
                                ? ""
                                : "folded"
                            : ""
                    }`}
                >
                    {task.description}
                </div>
                {showToggleDescription ? (
                    <p
                        className="show-all"
                        onClick={(event) => {
                            setShowFullDescription(!showFullDescription);
                        }}
                    >
                        {showFullDescription
                            ? "Свернуть"
                            : "Показать полностью"}
                    </p>
                ) : null}
            </div>
            <div className="user-monitor-right-bar-block-divider"></div>
            <div className="user-monitor-right-bar-block">
                <div className="block-name">ПОСЛЕДНИЕ КОММЕНТАРИИ</div>
                {task.lastComments[0] ? (
                    <div ref={commentRef} className="last-comments-block">
                        <div className="author">
                            <div className="name">
                                {initials(
                                    task.lastComments[0].author.surname +
                                        " " +
                                        task.lastComments[0].author.name +
                                        " " +
                                        task.lastComments[0].author.middlename
                                )}
                            </div>
                            <div className="relation-to-task">ИСПОЛНИТЕЛЬ</div>
                        </div>
                        <div className="date">
                            {dateToNiceString(
                                new Date(task.lastComments[0]?.date),
                                "month dd, yyyy"
                            )}
                        </div>
                        <div
                            id="last-comment-foldable-field"
                            className={`comment ${
                                showToggleComment
                                    ? showFullComment
                                        ? ""
                                        : "folded"
                                    : ""
                            }`}
                        >
                            <MarkdownPreview
                                content={task.lastComments[0]?.text}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {showToggleComment ? (
                    <p
                        className="show-all"
                        onClick={(event) => {
                            setShowFullComment(!showFullComment);
                        }}
                    >
                        {showFullComment ? "Свернуть" : "Показать полностью"}
                    </p>
                ) : null}
            </div>
            <div className="user-monitor-right-bar-block-divider"></div>
            <div className="user-monitor-right-bar-block">
                <div className="block-name">ПОСЛЕДНИЕ ИЗМЕНЕНИЯ</div>
            </div>
        </div>
    );
};

export default TaskDescription;
