import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import "../../styles/filesComponent.pc.css";
import "./HistoryProject.css";
import "../../../globalstyles/dataTableStyles.css";
import PersonLineDisplay from "../../Shared/PersonLineDisplay";
import OpenBlock from "../../../assets/openBlock.svg";
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import RightArrow from "../../../assets/rightArrow.svg";
import { Context } from "../../../index";
import { IHasPermission } from "../../../models/IChekRole";
import { fullNameFormat } from "../../../helpers/Inicials";
import { IProjectHistoryDisplay } from "../../../models/ProjectModels";
import { deepDiffMapper } from "../../../helpers/deppDiffMapper";
import { DoubleKeyMap } from "../../../helpers/DoubleKeyMap";

const historyEntities = new Map<string, string>([
    ["user", "Пользователь"],
    ["project", "Проект"],
    ["task", "Задача"],
    ["projectFile", "Файл проекта"],
    ["board", "Доска"],
    ["subProject", "Подпроект"],
    ["team", "Команда"],
    ["column", "Колонка"],
    ["role", "Роль"],
    ["securitySettings", "Настройки безопасности"],
    ["systemSettings", "Настройки системы"],
    ["taskFile", "Файл задачи"],
]);

const historyActions = new Map<string, string>([
    ["create", "Создание"],
    ["edit", "Редактирование"],
    ["delete", "Удаление"],
]);

/**
 * От этой мапы зависит что именно отображаем в таблице
 * сущность + действие = массив ключей в объектах oldData и newData для отображения
 * Массив ключей - это массив пар строк где первая это ключ, а вторая это название для отображения,
 * если в качестве массива ключей задать пустой массив, то столбец "Изменение" будет пустым,
 * если тип дейсвтия = "редактирование", а значение под ключом не изменено, оно не отобразится
 */
const historyMap: DoubleKeyMap<string, string, [string, string][]> =
    new DoubleKeyMap([
        ["project", "create", [["shortDescription", "Название"]]],
        [
            "project",
            "edit",
            [
                ["shortDescription", "Название"],
                ["description", "Описание"],
            ],
        ],
        ["project", "delete", []],
    ]);

const PAGE_SIZE = 5;

interface IChange {
    oldValue: string | undefined;
    newValue: string | undefined;
    valueName: string;
}

interface IProjectHistoryDisplayExtended extends IProjectHistoryDisplay {
    changes: IChange[];
}

interface IHistoryProjectProps {
    records: IProjectHistoryDisplay[];
    scrollEnded: boolean;
    loadPage: (skip: number, take: number) => void;
    userAccess: IHasPermission[];
}

function toDisplayDate(dateStr: any): string {
    try {
        let date = new Date(dateStr);
        const padWithZero = (value: number) =>
            value.toString().padStart(2, "0");
        const day = padWithZero(date.getDate());
        const month = padWithZero(date.getMonth() + 1);
        const year = date.getFullYear();

        //Перестанет работать в 2100 году :)
        return `${day}.${month}.${year - 2000}`;
    } catch (err: any) {
        return "-";
    }
}

const History: React.FC<IHistoryProjectProps> = ({
    records,
    scrollEnded,
    loadPage,
    userAccess,
}) => {
    const page = useRef<number>(0);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);
    const { store } = useContext(Context);
    const [recordsForView, setRecordsForView] = useState<
        IProjectHistoryDisplayExtended[]
    >([]);

    // Ограничение прав
    const [viewHistory, setViewHistory] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "projectHistory") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewHistory(true);
                    }
                });
            }
        });

        if (store.user.email == "admin@bpmlab.ru") {
            setViewHistory(true);
        }
    }, [userAccess]);

    useEffect(() => {
        setRecordsForView(parseRecords(records));
    }, [records]);

    const onScroll = (e: any) => {
        checkIfScrolledToBottom(e);
    };

    function checkIfScrolledToBottom(e: any) {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;

        if (1 + scrollTop + offsetHeight >= scrollHeight && !scrollEnded) {
            page.current++;
            loadPage(0, page.current * PAGE_SIZE);
        }
    }

    const toStr = (obj: any): string => {
        return JSON.stringify(obj ?? "");
    };

    const parseRecords = (
        records: IProjectHistoryDisplay[]
    ): IProjectHistoryDisplayExtended[] => {
        if (!records || !records.length) return [];
        return records.map((r) => {
            try {
                // Если keys == undefined, это ситуация когда в на сервере историю что-то добавили,
                // но на фронте еще нет обработки (a.k.a. записи в historyMap)
                const keys = historyMap.get(r.entityType, r.typeOfChange);

                const diff = deepDiffMapper.map(
                    r.changeData.oldData,
                    r.changeData.newData
                );

                let changes: IChange[] = [];

                if (r.typeOfChange === "create")
                    changes = parseCreatedRecord(r, keys, diff);
                else if (r.typeOfChange === "edit")
                    changes = parseChangedRecord(r, keys, diff);
                else changes = parseDeletedRecord(r, keys, diff);

                return {
                    createdAt: r.createdAt,
                    createdBy: r.createdBy,
                    entityId: r.entityId,
                    entityType: r.entityType,
                    typeOfChange: r.typeOfChange,
                    changeData: r.changeData,
                    changes: changes,
                };
            } catch (err: any) {
                console.error(err);
                return {
                    createdAt: r.createdAt,
                    createdBy: r.createdBy,
                    entityId: r.entityId,
                    entityType: r.entityType,
                    typeOfChange: r.typeOfChange,
                    changeData: r.changeData,
                    changes: [],
                };
            }
        });
    };

    const parseCreatedRecord = (
        record: IProjectHistoryDisplay,
        keys: [string, string][] | undefined,
        diff: any
    ): IChange[] => {
        let changes: IChange[] = [];

        if (!keys) {
            Object.keys(diff.newValue).forEach((key) => {
                changes.push({
                    oldValue: undefined,
                    newValue: toStr(diff.newValue[key]),
                    valueName: key,
                });
            });

            return changes;
        }

        if (diff && diff.type === "created") {
            const values = keys.map((key) => {
                return {
                    oldValue: undefined,
                    newValue: toStr(record.changeData.newData[key[0]]),
                    valueName: key[1],
                };
            });
            changes.push(...values);
        }

        return changes;
    };

    const parseChangedRecord = (
        record: IProjectHistoryDisplay,
        keys: [string, string][] | undefined,
        diff: any
    ): IChange[] => {
        let changes: IChange[] = [];

        if (!keys) {
            Object.keys(diff).forEach((key) => {
                if (diff[key].type !== "unchanged")
                    changes.push({
                        oldValue: toStr(diff[key].oldValue),
                        newValue: toStr(diff[key].newValue),
                        valueName: key,
                    });
            });

            return changes;
        }

        const values = keys.map((key) => {
            return {
                oldValue: toStr(record.changeData.oldData[key[0]]),
                newValue: toStr(record.changeData.newData[key[0]]),
                valueName: key[1],
            };
        });
        changes.push(...values);

        return changes;
    };

    const parseDeletedRecord = (
        record: IProjectHistoryDisplay,
        keys: [string, string][] | undefined,
        diff: any
    ): IChange[] => {
        let changes: IChange[] = [];

        if (!keys) {
            // Object.keys(diff.oldValue).forEach((key) => {
            //     changes.push({
            //         oldValue: toStr(diff.oldValue[key]),
            //         newValue: undefined,
            //         valueName: key,
            //     });
            // });

            // return changes;

            //Если нет ключей, не отображать ничего
            return [];
        }

        if (diff && diff.type === "deleted") {
            const values = keys.map((key) => {
                return {
                    oldValue: toStr(record.changeData.oldData[key[0]]),
                    newValue: undefined,
                    valueName: key[1],
                };
            });
            changes.push(...values);
        }

        return changes;
    };

    return (
        <div
            id="main-history-widget"
            className={
                openOnFullWindow
                    ? "widget_wrapper--full__window"
                    : "widget_wrapper"
            }
        >
            <div
                className={
                    openOnFullWindow ? "widget_wrapper-full__window--block" : ""
                }
            >
                <div className="widget_header">
                    <div className="widjets_header_left">
                        {openOnFullWindow ? (
                            <div
                                onClick={() => setOpenOnFullWindow(false)}
                                className="widgets_header--open__block"
                            >
                                <img src={CloseBlockWindow} />
                            </div>
                        ) : (
                            <div
                                onClick={() => setOpenOnFullWindow(true)}
                                className="widgets_header--open__block"
                            >
                                <img src={OpenBlock} />
                            </div>
                        )}
                        <h2 className="widget-card-header-style">
                            История проекта
                        </h2>
                    </div>
                </div>
                <div
                    onScroll={onScroll}
                    className="custom_table history_project_table"
                >
                    <table>
                        <thead>
                            <tr>
                                <th>ДАТА</th>
                                <th>ТИП ОБЪЕКТА</th>
                                <th>ТИП ИЗМЕНЕНИЯ</th>
                                <th>ПОЛЬЗОВАТЕЛЬ</th>
                                <th>ИЗМЕНЕНИЕ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {viewHistory &&
                                recordsForView.map((record, index) => (
                                    <tr key={index}>
                                        <td style={{ width: "10%" }}>
                                            {toDisplayDate(record.createdAt)}
                                        </td>
                                        <td style={{ width: "12%" }}>
                                            {historyEntities.get(
                                                record.entityType
                                            )}
                                        </td>
                                        <td style={{ width: "15%" }}>
                                            {historyActions.get(
                                                record.typeOfChange
                                            )}
                                        </td>
                                        <td style={{ width: "20%" }}>
                                            <PersonLineDisplay
                                                name={fullNameFormat(
                                                    {
                                                        surname:
                                                            record.createdBy
                                                                .surname,
                                                        name: record.createdBy
                                                            .name,
                                                    },
                                                    "s N"
                                                )}
                                                photoId={
                                                    record.createdBy.photoId
                                                }
                                            />
                                        </td>
                                        <td style={{ width: "55%" }}>
                                            <div>
                                                {record.changes.map(
                                                    (change) => (
                                                        <div>
                                                            {change.valueName +
                                                                " : "}
                                                            {change.oldValue}
                                                            {change.oldValue &&
                                                            change.newValue ? (
                                                                <img
                                                                    src={
                                                                        RightArrow
                                                                    }
                                                                    alt=""
                                                                    height={10}
                                                                />
                                                            ) : null}
                                                            {change.newValue}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default observer(History);
