export const deepDiffMapper = (function () {
    return {
        VALUE_CREATED: "created" as const,
        VALUE_UPDATED: "updated" as const,
        VALUE_DELETED: "deleted" as const,
        VALUE_UNCHANGED: "unchanged" as const,

        map: function (obj1: any, obj2: any): any {
            if (this.isFunction(obj1) || this.isFunction(obj2)) {
                throw "Invalid argument. Function given, object expected.";
            }
            if (this.isValue(obj1) || this.isValue(obj2)) {
                return {
                    type: this.compareValues(obj1, obj2),
                    oldValue: obj1,
                    newValue: obj2,
                };
            }

            const diff: { [key: string]: any } = {};
            for (const key in obj1) {
                if (this.isFunction(obj1[key])) {
                    continue;
                }

                const value2 = obj2[key] !== undefined ? obj2[key] : undefined;
                diff[key] = this.map(obj1[key], value2);
            }
            for (const key in obj2) {
                if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
                    continue;
                }

                diff[key] = this.map(undefined, obj2[key]);
            }

            return diff;
        },

        compareValues: function (value1: any, value2: any): string {
            if (value1 === value2) {
                return this.VALUE_UNCHANGED;
            }
            if (
                this.isDate(value1) &&
                this.isDate(value2) &&
                value1.getTime() === value2.getTime()
            ) {
                return this.VALUE_UNCHANGED;
            }
            if (value1 === undefined) {
                return this.VALUE_CREATED;
            }
            if (value2 === undefined) {
                return this.VALUE_DELETED;
            }
            return this.VALUE_UPDATED;
        },

        isFunction: function (x: any): boolean {
            return Object.prototype.toString.call(x) === "[object Function]";
        },

        isArray: function (x: any): boolean {
            return Object.prototype.toString.call(x) === "[object Array]";
        },

        isDate: function (x: any): boolean {
            return Object.prototype.toString.call(x) === "[object Date]";
        },

        isObject: function (x: any): boolean {
            return Object.prototype.toString.call(x) === "[object Object]";
        },

        isValue: function (x: any): boolean {
            return !this.isObject(x) && !this.isArray(x);
        },
    };
})();
