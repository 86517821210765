
import { FC, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { observer } from 'mobx-react-lite';
import './Filter.css'
import { ListBox } from 'primereact/listbox';
import { Calendar } from 'primereact/calendar';
import Arrow from "../../../assets/arrowFilter.svg"
import Checkbox from '../Checkbox/Checkbox';
import { IFilter } from "../../../models/response/IFilterResponse";

interface IFilterProps {
    items: IFilter[];
    setItems: (filterData: IFilter[]) => void;
    applayFilters: () => Promise<void>;
    checkedFilters: any;
    setCheckedFilters: (checkedFilters: any) => void;
    selectedUsers?: any 
    dates?: any
    setSelectedUsers?: (selectedUsers: any) => void
    setDates?: (dates: any) => void
    setCounter: (counter: number) => void
    showApplyButton: boolean;
    setOpen:(open: boolean) => void
}


const Filter: FC<IFilterProps> = ({ items, setItems, applayFilters,
    checkedFilters, setCheckedFilters, selectedUsers, dates, setSelectedUsers,
    setDates, setCounter, showApplyButton, setOpen}) => {

    const toggleFilter = (label: string) => {
        let data = items;
        let currItems = items.find((i: IFilter) => i.label === label);
        let currIndex = items.findIndex((i: IFilter) => i.label === label);
        let closeItem = items.find((i: IFilter) => i.open);
        let indexCloseItem = items.findIndex(i => i.open);
        if (currItems && currIndex !== -1 && currItems.label !== closeItem?.label) {
            currItems.open = !currItems.open
            let left = items.slice(0, currIndex);
            let rigth = data.slice(currIndex + 1);
            setItems([...left, currItems, ...rigth]);
        }

        if (closeItem && indexCloseItem !== -1) {
            closeItem.open = !closeItem.open
            let left = items.slice(0, indexCloseItem);
            let rigth = data.slice(indexCloseItem + 1);
            setItems([...left, closeItem, ...rigth])
        }
    }


    const handleSelection = (idFilter: number, event: any, nameForFilter?: string) => {
       
        let data = items;
        let filter = structuredClone(checkedFilters);
        if (event.target.checked) {
            if (nameForFilter) {
                if (filter[nameForFilter]?.length) {
                    filter[nameForFilter] = [...filter[nameForFilter], Number(idFilter)]
                } else {
                    filter[nameForFilter] = [Number(idFilter)]
                }
            }
        } else {
            if (nameForFilter) {
                let index = filter[nameForFilter].findIndex((i: any) => i === Number(idFilter));
                if (index !== -1) {
                    filter[nameForFilter].splice(index, 1);
                    filter[nameForFilter]?.length === 0 && (filter[nameForFilter] = undefined);
                }

            }

        }
        setCheckedFilters(filter);


        let currItems = items.find(i => i.nameForFilter === nameForFilter);
   
        if (filter[nameForFilter!]?.length) {
            currItems && (currItems.check = 'Выбрано');
        } else {
            currItems && (currItems.check = undefined);
        }

        let currIndex = items.findIndex((i: IFilter) => i.nameForFilter === nameForFilter);

        if (currItems && currIndex !== -1) {
            let left = items.slice(0, currIndex);
            let rigth = data.slice(currIndex + 1);
            setItems([...left, currItems, ...rigth])

            let currCheckedItem = (currItems as any).items?.find((i: any) => i.id === idFilter);
            let currCheckedItemIntex = (currItems as any).items?.findIndex((i: any) => i.id === idFilter);

            if (currCheckedItem && currCheckedItemIntex !== -1) {
                let itemsData = currItems.items!;
                currCheckedItem.checked = !currCheckedItem.checked;
                let left = currItems.items!.slice(0, currCheckedItemIntex);
                let rigth = itemsData.slice(currCheckedItemIntex + 1);
                currItems.items = [...left, currCheckedItem, ...rigth];

                let left2 = items.slice(0, currIndex);
                let rigth2 = data.slice(currIndex + 1);
                setItems([...left2, currItems, ...rigth2])
            }
        }
    }


    const handelDataOrUeser = (value: any, nameForFilter: string) => {
        let data = items;
        let filter = structuredClone(checkedFilters);
        if (nameForFilter === 'responsible') {
            setSelectedUsers && setSelectedUsers(value);
            filter.responsible = value ? value.map((i: any) => Number(i.code)) : undefined;
        } else if (nameForFilter === 'date') {
            setDates && setDates(value);
            value[0] !== null && (filter.date = value.filter((i: any) => i !== null).map((i: Date) => i.toISOString()));

        }
        setCheckedFilters(filter);

        let currItems = items.find(i => i.nameForFilter === nameForFilter);
        if (filter[nameForFilter!]?.length) {
            currItems && (currItems.check = 'Выбрано');
        } else {
            currItems && (currItems.check = undefined);
        }

        let currIndex = items.findIndex((i: IFilter) => i.nameForFilter === nameForFilter);
        if (currItems && currIndex !== -1) {
            let left = items.slice(0, currIndex);
            let rigth = data.slice(currIndex + 1);
            setItems([...left, currItems, ...rigth])
        }
    }

    const search = async () => {
        setOpen(false);
        await applayFilters();
    }

    

    return (
        <div className="filter_container" >
            {items && items.map((i: IFilter) => (
                <ul className='p_black p_12'>
                    <li className='filter_item ' onClick={() => toggleFilter(i.label)}>
                        <div className='filter_item_left'>
                            <img src={Arrow} />
                            <div className='filter_label'>
                                {i.label}
                            </div>
                        </div>
                        <div className='filter_check p_gray_light '>
                            {i.check ? i.check : "Не выбрано"}
                        </div>

                    </li>
                    {i.open && i.items && !i.type &&
                        <ul className='filter_items'>
                            {i.items.map((x: any) => (
                                <li className='filter_item_inner'>

                                    <Checkbox id={x.id} handleSelection={handleSelection} nameForFilter={i.nameForFilter} checked={x.checked} />
                                    <div className='filter_label'>
                                        {x.label}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    }
                    {i.open && i.type && i.type == 'users' && i.items &&
                        <div className='filter_items filter_users'>
                            <ListBox filterPlaceholder="Поиск..." listStyle={{ maxHeight: '150px' }} multiple filter value={selectedUsers} onChange={(e) => handelDataOrUeser(e.value, i.nameForFilter)} options={i.items} optionLabel="name" className="w-full md:w-14rem" />
                        </div>
                    }
                    {i.open && i.type && i.type == 'date' &&
                        <div className='filter_items'>
                            <Calendar value={dates} onChange={(e: any) => handelDataOrUeser(e.value, i.nameForFilter)} selectionMode="range" readOnlyInput hideOnRangeSelection dateFormat='dd.mm.yy'/>
                        </div>
                    }

                </ul>
            ))
            }
            {showApplyButton ? (
                <div className="filterBtnApply">
                    <Button label="Применить" onClick={search} />
                </div>
            ) : null}
        </div >

    )
}

export default observer(Filter);        