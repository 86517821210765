import React, { useContext, useEffect, useState } from "react";
import CancelButton from "../../buttons/CancelButton/CancelButton";
import Search from "../../Shared/Search/Search";
import "./BoardToolbar.css";
import BtnDelete from "../../buttons/btnDelete.component/BtnDelete";
import BtnMove from "../../buttons/MoveButton/MoveButton";
import BtnSettings from "../../buttons/SettingsButton/SettingsButton";
import ActionModeButton from "../../buttons/BoardActionModeButton/ActionModeButton";
import BoardFastFilters from "../BoardFastFilters/BoardFastFilters";
import { BoardContext } from "../../../pages/Board/Board";
import BtnFilter from "../../buttons/btnFilter.component/BtnFilter";
import BtnCancel from "../../../component/buttons/CancelButton/CancelButton";
import { AppContext } from "../../../App";
import { Context } from "../../../index";
import {IHasPermission} from "../../../models/IChekRole";

export interface IToolbarProps {
    onMassActionsFunc: () => void;
    selectedCount: number;
    userAccess: IHasPermission[]
}

const BoardToolbar: React.FC<IToolbarProps> = ({onMassActionsFunc, selectedCount, userAccess}) => {
    const [showMoveDropdown, setShowMoveDropdown] = useState<boolean>();
    const { showToast } = useContext(AppContext);
    const { store } = useContext(Context);

    // Ограничение прав
    const [editBoard, setEditBoard] = useState(false);
    const [delTask, setDelTask] = useState(false);
    const [editTask, setEditTask] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "BoardAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditBoard(true);
                    }
                })
            }
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDelTask(true);
                    }
                    if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        setEditTask(true);
                    }
                })
            }
        })

        if (store.user.email == "admin@bpmlab.ru") {
            setEditBoard(true);
            setDelTask(true);
            setEditTask(true);
        }
    }, [userAccess]);

    const {
        taskNameSearch,
        setTaskNameSearch,
        actionMode,
        cancelActionMode,
        handleDeleteTasks,
        handleMoveTasks,
        selectedTasks,
        columns,
        openMenu,
        boardFilters,
        setBoardFilters,
        checkedBoardFilters,
        setCheckedBoardFilters,
        datesFilter,
        setDatesFilter,
        usersFilter,
        setUsersFilter,
        filterCounter,
        setFilterCounter,
        resetFilter,
        taskFilters,
        changeFastFilters,
        selectedFastFilters,
    } = useContext(BoardContext);

    const handleDeleteSubmit = () => {
        if (delTask) {
            handleDeleteTasks(selectedTasks);
        } else {
            showToast("У вас нет прав для удаления задач(и)!");
        }
    };

    const handleActionModeClick = () => {
        onMassActionsFunc();
    };

    const handleColumnClick = (e: any, columnId: number) => {
        handleMoveTasks(columnId);
        setShowMoveDropdown(false);
        e.stopPropagation();
    };

    function onCancelClick() {
        resetFilter();
    }

    return (
        <div className="board-toolbar">
            <div className="toolbar-left">
                <BoardFastFilters
                    taskFilters={taskFilters}
                    changeFastFilters={changeFastFilters}
                    selectedFastFilters={selectedFastFilters}
                />
            </div>
            <div className="toolbar-right" style={{ alignSelf: "end" }}>
                {actionMode ? (
                    <CancelButton onClickFunc={cancelActionMode} />
                ) : null}
                {actionMode && selectedCount > 0 && (
                    <div className="mass-actions">
                        <BtnMove
                            onClickFunc={() =>
                                setShowMoveDropdown(!showMoveDropdown)
                            }
                            permission={editTask}
                        />
                        {showMoveDropdown ? (
                            <div className="move-task-popup-container">
                                <div
                                    className="move-task-popup-overlay"
                                    onClick={() => {
                                        setShowMoveDropdown(false);
                                    }}
                                ></div>
                                <div className="move-task-popup">
                                    <p className="move-task-popup-header">
                                        Переместить в колонку
                                    </p>
                                    <div className="move-task-popup-column-container">
                                        {columns.map((c) => (
                                            <p
                                                className="move-task-popup-column-text"
                                                onClick={(e) =>
                                                    handleColumnClick(e, c.id)
                                                }
                                            >
                                                {c.name}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <BtnDelete
                            onClickFunc={handleDeleteSubmit}
                            permission={delTask}
                        />
                    </div>
                )}
                {actionMode ? (
                    <span className="toolbar__point--task__counter">
                        {selectedCount}
                    </span>
                ) : (
                    <span className="toolbar__point--task__counter"></span>
                )}
                <ActionModeButton
                    onClickFunc={handleActionModeClick}
                    isActive={actionMode}
                    selectedCount={1}
                />
                <Search
                    defaultValue={taskNameSearch}
                    onSubmitFunc={() => {}}
                    changeSearchValue={setTaskNameSearch}
                />
                <BtnFilter
                    counter={filterCounter}
                    setCounter={setFilterCounter}
                    filterData={boardFilters}
                    setFilterData={setBoardFilters}
                    applayFilters={async () => {}}
                    checkedFilters={checkedBoardFilters}
                    setCheckedFilters={setCheckedBoardFilters}
                    dates={datesFilter}
                    setDates={setDatesFilter}
                    selectedUsers={usersFilter}
                    setSelectedUsers={setUsersFilter}
                    showApplyButton={false}
                />
                {(filterCounter !== 0 || taskNameSearch) && (
                    <BtnCancel onClickFunc={onCancelClick} />
                )}
                <BtnSettings onClickFunc={openMenu} permission={editBoard} />
            </div>
        </div>
    );
};

export default BoardToolbar;
