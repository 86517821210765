import React, {useContext, useEffect, useState} from "react";
import ColorPicker from "../../Shared/ColorPicker";
import {BoardContext} from "../../../pages/Board/Board";
import ColumnWeightInput from "../ColumnWeightInput/ColumnWeightInput";
import "./CreateColumnForm.css"
import {IHasPermission} from "../../../models/IChekRole";
import {Context} from "../../../index";
import {AppContext} from "../../../App";

export interface ICreateColumnForm {
    userAccess: IHasPermission[]
}

const CreateColumnForm: React.FC<ICreateColumnForm> = ({userAccess}) => {
    const MIN_CHARACTERS = 5;
    const { store } = useContext(Context);
    const { showToast } = useContext(AppContext);

    const {handleAddColumn, boardsForProxy, colorOptions, taskFilters, isProxyBoard} =
        useContext(BoardContext);

    const [addProxyColumn, setAddProxyColumn] = useState<boolean>(false);
    const [existingBoard, setExistingBoard] = useState<boolean>(false);
    const [selectedBoardId, setSelectedBoardId] = useState<number | undefined>(
        boardsForProxy[0]?.id
    );
    const [newColumnName, setNewColumnName] = useState<string>("");
    const [proxyBoardName, setProxyBoardName] = useState<string>("");
    const [columnColor, setColumnColor] = useState<string>();
    const [weight, setWeight] = useState<number>(0);
    const [columnPriorityId, setColumnPriorityId] = useState<
        number | undefined
    >(taskFilters?.priority[0]?.id);
    const [columnStatusId, setColumnStatusId] = useState<number | undefined>(
        taskFilters?.status[0]?.id
    );

    // Ограничение прав
    const [addProxyColumnInMenu, setAddProxyColumnInMenu] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "ColumnProxyAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddProxyColumnInMenu(true);
                    }
                })
            }
        })

        if (store.user.email == "admin@bpmlab.ru") {
            setAddProxyColumnInMenu(true);
        }
    }, [userAccess]);

    const handleChangeColumnName = (e: any) => {
        setNewColumnName(e.target.value);
    };

    const handleChangeProxyBoardName = (e: any) => {
        setProxyBoardName(e.target.value);
    };

    const onAddColumn = () => {
        //Черновая валидация, нужно поправить
        if (newColumnName.length < MIN_CHARACTERS) {
            alert(`Имя должно быть больше ${MIN_CHARACTERS} символов`);
            return;
        }
        if (addProxyColumn) {
            // if (!existingBoard && proxyBoardName.length < MIN_CHARACTERS ) {
            //     alert(
            //         `Имя доски должно быть больше ${MIN_CHARACTERS} символов`
            //     );
            //     return;
            // } else if (!selectedBoardId) {
            //     alert(`Не выбрана доска для привязки`);
            //     return;
            // }
        }
        if (!columnColor) {
            alert("Не выбран цвет");
            return;
        }
        if (!columnPriorityId || !columnStatusId) {
            alert("Не выбран статус или приоритет");
            return;
        }
        handleAddColumn(
            newColumnName,
            columnColor,
            weight,
            // columnStatusId,
            // columnPriorityId,
            undefined,
            undefined,
            addProxyColumn ? proxyBoardName?.length ? proxyBoardName : newColumnName : undefined,
            existingBoard ? selectedBoardId : undefined
        );
        setProxyBoardName("");
        setNewColumnName("");
        setAddProxyColumn(false);
    };

    const handleEditWeight = (newWeight: number) => {
        setWeight(newWeight);
    };

    return (
        <div className="create__column--block">
            <div className="create__column--block--name">
                <input
                    type="text"
                    value={newColumnName}
                    onChange={handleChangeColumnName}
                    placeholder="Название столбца"
                ></input>
            </div>
            <div className="create__column--block--color">
                <div className="create__column--block--color__name">
                    Цвет столбца
                </div>
                <ColorPicker
                    onColorSelected={(color) => setColumnColor(color)}
                    options={colorOptions}
                />
            </div>
            {/*<div className="create__column--block--status">*/}
            {/*    <label className="create__column--block--status--name">*/}
            {/*        Статус*/}
            {/*    </label>*/}
            {/*    <select*/}
            {/*        onChange={(e) => setColumnStatusId(Number(e.target.value))}*/}
            {/*    >*/}
            {/*        {taskFilters.status.map((status) => (*/}
            {/*            <option key={status.id} value={status.id}>*/}
            {/*                {status.name}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </select>*/}
            {/*</div>*/}
            {/*<div className="create__column--block--status">*/}
            {/*    <label  className="create__column--block--status--name">*/}
            {/*        Приоритет*/}
            {/*    </label>*/}
            {/*    <select*/}
            {/*        onChange={(e) =>*/}
            {/*            setColumnPriorityId(Number(e.target.value))*/}
            {/*        }*/}
            {/*    >*/}
            {/*        {taskFilters.priority.map((priority) => (*/}
            {/*            <option key={priority.id} value={priority.id}>*/}
            {/*                {priority.name}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </select>*/}
            {/*</div>*/}
            <ColumnWeightInput onChangeWeight={handleEditWeight} edit={false}/>
            {isProxyBoard ? (<div></div>) : (
                <div className="create__column--block--proxy">
                    <label style={addProxyColumnInMenu ? {} : {color: "rgb(175, 175, 175)"}}>Прокси-столбец</label>
                    <input
                        style={addProxyColumnInMenu ? {} : {borderColor: "rgb(175, 175, 175)"}}
                        type="checkbox"
                        checked={addProxyColumn}
                        onChange={() => {addProxyColumnInMenu ? setAddProxyColumn(!addProxyColumn) : showToast("У вас нет прав на создание прокси столбца!") }}
                    ></input>
                </div>)}
            {addProxyColumn ? (
                <div>
                    {/*{boardsForProxy && boardsForProxy.length > 0 ? (*/}
                    {/*<div className="create__column--block--proxy">*/}
                    {/*        <label>Существующая доска</label>*/}
                    {/*        <input*/}
                    {/*            type="checkbox"*/}
                    {/*            checked={existingBoard}*/}
                    {/*            onChange={() =>*/}
                    {/*                setExistingBoard(!existingBoard)*/}
                    {/*            }*/}
                    {/*        ></input>*/}
                    {/*    </div>*/}
                    {/*) : null}*/}
                    {/*{existingBoard ? (*/}
                    {/*    <div className="create__column--block--proxy">*/}
                    {/*        <select*/}
                    {/*            onChange={(e) =>*/}
                    {/*                setSelectedBoardId(Number(e.target.value))*/}
                    {/*            }*/}
                    {/*        >*/}
                    {/*            {boardsForProxy.map((b) => (*/}
                    {/*                <option key={b.id} value={b.id}>*/}
                    {/*                    {b.name}*/}
                    {/*                </option>*/}
                    {/*            ))}*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*) : (*/}
                    <div className="create__column--block--proxy__input">
                        <input
                            value={proxyBoardName}
                            onChange={handleChangeProxyBoardName}
                            placeholder="Название доски"
                        ></input>
                    </div>
                    {/*)}*/}
                </div>
            ) : null}
            <div className="create__column--block--button">
                <button onClick={onAddColumn}>
                    Сохранить
                </button>
            </div>
        </div>
    );
};

export default CreateColumnForm;
