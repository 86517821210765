import {createRef, FC, useContext, useEffect, useState} from 'react';
import "./BasicTab.css"
import Background from "../../../assets/default__background.jpg";
import EditIconWhite from "../../../assets/EditIconWhite.svg"
import DefaultCompanyLogo from "../../../assets/logo.svg"
import {Context} from "../../../index";
import {IEditElemSettings, IElemSettings} from "../../../models/IElemSettings";
import CrossBlue from "../../../assets/cancel.svg"
import Cropper, {ReactCropperElement} from "react-cropper";
import "cropperjs/dist/cropper.css";
import { AppContext } from '../../../App';
import { getFileUrlFromId } from '../../../helpers/getFileUrlFromId';

const MAX_SIZE_IMG_MB = 5;


/**
 * TODO Функции по работе с изображениями были перенесены в ImageSelector,
 * но здесь не заменены
 * */
const BasicTab: FC = (index) => {
    const { store } = useContext(Context);
    const [image, setImage] = useState<string>("");
    const { showToast } = useContext(AppContext);
    const [modeCrop, setModeCrop] = useState<string>("");
    const [cropData, setCropData] = useState("#");
    const [openModal, setOpenModal] = useState(false);
    const [openErrorImg, setOpenErrorImg] = useState(false);
    const cropperRef = createRef<ReactCropperElement>();
    const [dataSystem, setDataSystem] = useState<IElemSettings[]>([]);
    const [nameCompany, setNameCompany] = useState<string | undefined>("");
    const [descriptionCompany, setDescriptionCompany] = useState<string | undefined>("");

    // Логотип
    const [newLogo, setNewLogo] = useState<{url: string, fileId: number} | undefined>(undefined);
    const [hrefLogoCompany, setHrefLogoCompany] = useState<string | undefined>("");
    // Основное фото
    const [newCover, setNewCover] = useState<{url: string, fileId: number} | undefined>(undefined);
    const [hrefCoverCompany, setHrefCoverCompany] = useState<string | undefined>("");
    // Валидация размера окна
    const [validate, setValidate] = useState<boolean>(false);


    useEffect(() => {
        (async () => {
            try {
                const data = await store.getSystemSettings();
                setDataSystem(data);
                if (data) {
                    data?.forEach((xx) => {
                        xx.paramName == "name" && setNameCompany(xx.value);
                        xx.paramName == "description" && setDescriptionCompany(xx.value);
                        xx.paramName == "logo" && setHrefLogoCompany(getFileUrlFromId(xx.fileId));
                        xx.paramName == "cover" && setHrefCoverCompany(getFileUrlFromId(xx.fileId));
                    })
                }
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);

    function changeNameCompany(event: any) {
        setNameCompany(event.target.value);
    }

    function changeDescriptionCompany(event: any) {
        setDescriptionCompany(event.target.value?.length ? event.target.value : undefined);
    }

    function checkUpdate(): boolean {
        let trigger = false;
        dataSystem?.length && dataSystem?.forEach((xx) => {
            if (xx.paramName == "name" && xx.value != nameCompany) trigger = true;
            if (xx.paramName == "description" && xx.value != descriptionCompany) trigger = true;
            if (xx.paramName == "logo" && newLogo != undefined && getFileUrlFromId(xx.fileId) != newLogo?.url) trigger = true;
            if (xx.paramName == "cover" && newCover != undefined && getFileUrlFromId(xx.fileId) != newCover?.url) trigger = true;
        })

        let newName = dataSystem?.length &&  dataSystem?.find(xx => xx.paramName == "name")
        if (!newName && nameCompany != undefined) {
            trigger = true;
        }

        let newDescription = dataSystem?.length &&  dataSystem?.find(xx => xx.paramName == "description")
        if (!newDescription && descriptionCompany != undefined) {
            trigger = true;
        }


        let newCoverUrl = dataSystem?.length &&  dataSystem?.find(xx => xx.paramName == "cover")
        if (!newCoverUrl && newCover != undefined) {
            trigger = true;
        }

        let newLogoUrl = dataSystem?.length &&  dataSystem?.find(xx => xx.paramName == "logo")
        if (!newLogoUrl && newLogo != undefined) {
            trigger = true;
        }

        return trigger;
    }

    async function saveChange() {
        let newSettings: IEditElemSettings[] = [];
        let changeSettings: IEditElemSettings[] = [];
        if (nameCompany) {
            if (dataSystem?.length && dataSystem?.find((xx) => xx.paramName == "name")) {
                changeSettings.push({paramName: "name", value: nameCompany, fileId: undefined})
            } else {
                newSettings.push({paramName: "name", value: nameCompany, fileId: undefined})
            }
        }
        if (descriptionCompany) {
            if (dataSystem?.length && dataSystem?.find((xx) => xx.paramName == "description")) {
                changeSettings.push({paramName: "description", value: descriptionCompany, fileId: undefined})
            } else {
                newSettings.push({paramName: "description", value: descriptionCompany, fileId: undefined})
            }
        } else {

            if (dataSystem?.length && dataSystem?.find((xx) => xx.paramName == "description")) {
                changeSettings.push({paramName: "description", value: '', fileId: undefined})
            } else {
                newSettings.push({paramName: "description", value: '', fileId: undefined})
            }
        }
        if (newLogo) {
            if (dataSystem?.length && dataSystem?.find((xx) => xx.paramName == "logo")) {
                changeSettings.push({paramName: "logo", value: undefined, fileId: newLogo?.fileId})
            } else {
                newSettings.push({paramName: "logo", value: undefined, fileId: newLogo?.fileId})
            }
        }
        if (newCover) {
            if (dataSystem?.length && dataSystem?.find((xx) => xx.paramName == "cover")) {
                changeSettings.push({paramName: "cover", value: undefined, fileId: newCover.fileId})
            } else {
                newSettings.push({paramName: "cover", value: undefined, fileId: newCover.fileId})
            }
        }

        if (newSettings) {
            await store.addSystemSettings(newSettings);
        }
        if (changeSettings) {
            await store.editSystemSettings(changeSettings);
        }
        if (newSettings || changeSettings)
            showToast("Настройки сохранены");
        const data = await store.getSystemSettings();
        setDataSystem(data);
        if (data) {
            data?.forEach((xx) => {
                xx.paramName == "name" && setNameCompany(xx.value);
                xx.paramName == "description" && setDescriptionCompany(xx.value);
                xx.paramName == "logo" && setHrefLogoCompany(getFileUrlFromId(xx.fileId));
                xx.paramName == "cover" && setHrefCoverCompany(getFileUrlFromId(xx.fileId))
            })
        }
    }

    function disableCrop() {
        cleanInput();
        setImage("");
        setOpenModal(false);
    }

    function cleanInput() {
        const inputElement = document.getElementById('change__logo--id');
        if (inputElement instanceof HTMLInputElement) {
            inputElement.value = '';
        } else {
            console.error('Элемент с id="change__logo--id" не найден!');
        }
    }

    function deleteCover() {
        (async () => {
            try {
                const cover = await store.deleteImg("cover");
                const data = await store.getSystemSettings();
                setDataSystem(data);
                setHrefCoverCompany(undefined);
                setNewCover(undefined);
                if (data) {
                    data?.forEach((xx) => {
                        xx.paramName == "name" && setNameCompany(xx.value);
                        xx.paramName == "description" && setDescriptionCompany(xx.value);
                        xx.paramName == "logo" && setHrefLogoCompany(getFileUrlFromId(xx.fileId));
                        xx.paramName == "cover" && setHrefCoverCompany(getFileUrlFromId(xx.fileId))
                    })
                }
            } catch (error) {
                console.log(error)
            }
        })();
    }

    function deleteLogo() {
        (async () => {
            try {
                const logo = await store.deleteImg("logo");
                const data = await store.getSystemSettings();
                setDataSystem(data);
                setHrefLogoCompany(undefined);
                setNewLogo(undefined);
                if (data) {
                    data?.forEach((xx) => {
                        xx.paramName == "name" && setNameCompany(xx.value);
                        xx.paramName == "description" && setDescriptionCompany(xx.value);
                        xx.paramName == "logo" && setHrefLogoCompany(getFileUrlFromId(xx.fileId));
                        xx.paramName == "cover" && setHrefCoverCompany(getFileUrlFromId(xx.fileId))
                    })
                }
            } catch (error) {
                console.log(error)
            }
        })();
    }

    function defaultsettings() {
        let newNameCompany = dataSystem?.length && dataSystem?.find(xx => xx.paramName == "name")
        if (newNameCompany) {
            setNameCompany(newNameCompany.value);
        } else {
            setNameCompany("");
        }

        let newDescriptionCompany = dataSystem?.length && dataSystem?.find(xx => xx.paramName == "description")
        if (newDescriptionCompany) {
            setDescriptionCompany(newDescriptionCompany.value);
        } else {
            setDescriptionCompany("");
        }

        let newHrefLogoCompany = dataSystem?.length && dataSystem?.find(xx => xx.paramName == "logo")
        if (newHrefLogoCompany) {
            setHrefLogoCompany(getFileUrlFromId(newHrefLogoCompany.fileId));
            setNewLogo(undefined);
        } else {
            setHrefLogoCompany("");
            setNewLogo(undefined);
        }

        let newHrefCoverCompany = dataSystem?.length && dataSystem?.find(xx => xx.paramName == "cover")
        if (newHrefCoverCompany) {
            setHrefCoverCompany(getFileUrlFromId(newHrefCoverCompany.fileId));
            setNewCover(undefined);
        } else {
            setHrefCoverCompany("");
            setNewCover(undefined);
        }
    }

    const onChangeLogo = (e: any, mode: string) => {
        const file = e.target.files?.[0];
        const fileSizeInMB = file.size / (1024 * 1024);

        if (fileSizeInMB > MAX_SIZE_IMG_MB) {
            setOpenErrorImg(true);
            return;
        }

        setModeCrop(mode);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
        setOpenModal(true);
        cleanInput();
    };

    const dataURLToBlob = (dataURL: string): Blob => {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            let size = cropperRef.current.cropper.getContainerData();
            setValidate(false)
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
            const dataURL = croppedCanvas.toDataURL('image/png', 0.8);
            const croppedImageBlob = dataURLToBlob(dataURL);

            if (croppedImageBlob) {
                // setCropData(dataURL);
                // setHrefLogoCompany(dataURL);

                const croppedFile = new File([croppedImageBlob], 'Logo.png', { type: 'image/png' });
                (async () => {
                    try {
                        const newFile = await store.addSystemFile(croppedFile);
                        if (modeCrop == "logo") {
                            setNewLogo(newFile);
                        } else if (modeCrop == "cover") {
                            setNewCover(newFile);
                        }
                        setOpenModal(false);
                    } catch (error) {
                        console.log(error);
                    }
                })();
                setImage("");
            } else {
                console.error('Ошибка при получении обрезанного изображения!');
            }
        }
    };

    return (
        <div className="basic__tab">
            {openErrorImg ?
                <div className="basic__tab--modal__window">
                    <div className="basic__tab--modal__window--error__img">
                        <div className="basic__tab--modal__window--error__img--header">
                            Размер файла должен быть не более 5 Мб!
                        </div>
                        <div className="basic__tab--modal__window--error__img--buttons">
                            <button onClick={()=>(setOpenErrorImg(false))} className="basic__tab--modal__window--block__button--crop">
                                ОК
                            </button>
                        </div>
                    </div>
                </div>
            :
                null
            }
            {openModal &&
                <div className="basic__tab--modal__window">
                    <div className="basic__tab--modal__window--block">
                        <div>
                            <Cropper
                                ref={cropperRef}
                                style={{height: "600px", minWidth: "600px"}}
                                zoomTo={0.5}
                                initialAspectRatio={1}
                                src={image}
                                viewMode={1}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false}
                                guides={true}
                                className={validate ? "basic__tab--setting__elem--cover__block--crop" : ''}
                            />
                            <div className="basic__tab--modal__window--block__button">
                                <div className="basic__tab--modal__window--block__button--background">
                                    <button className="basic__tab--modal__window--block__button--close"
                                            onClick={disableCrop}>
                                        Отмена
                                    </button>
                                    <button className="basic__tab--modal__window--block__button--crop"
                                            onClick={getCropData}>
                                        Обрезать
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div>
                <h2 className="basic__tab--setting__elem--h2">Обложка</h2>
                <div className="basic__tab--setting__elem--cover__block">
                    <div className="basic__tab--setting__elem--cover">
                        <img src={newCover?.fileId ? getFileUrlFromId(newCover.fileId) : hrefCoverCompany ? hrefCoverCompany : Background}/>
                    </div>
                    <div className="basic__tab--setting__elem--company__logo__button">
                        <label
                            className="basic__tab--setting__elem--input-file basic__tab--setting__elem--input-file__task--size">
                            <input onChange={(e) => {onChangeLogo(e, 'cover'); e.target.value = '';}} type="file" accept="image/png, image/jpeg"/>
                            <span className="basic__tab--setting__elem--input-file__task-btn">
                                <img src={EditIconWhite}/>
                            </span>
                        </label>
                        <button className="basic__tab--setting__elem--company__logo--button__cross" onClick={deleteCover}>
                            <img src={CrossBlue}/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="basic__tab--setting__elem">
                <h2 className="basic__tab--setting__elem--h2">Логотип</h2>
                <div className="basic__tab--setting__elem--cover__block--logo">
                    <div className="basic__tab--setting__elem--company__logo">
                        <img src={newLogo?.fileId ? getFileUrlFromId(newLogo.fileId) : hrefLogoCompany ? hrefLogoCompany : DefaultCompanyLogo}/>
                    </div>
                    <div className="basic__tab--setting__elem--company__logo__button">
                        <label
                            className="basic__tab--setting__elem--input-file basic__tab--setting__elem--input-file__task--size">
                            <input onChange={(e) => {
                                onChangeLogo(e, 'logo');
                                e.target.value = '';
                            }} type="file" id="change__logo--id"/>
                            <span className="basic__tab--setting__elem--input-file__task-btn">
                                <img src={EditIconWhite}/>
                            </span>
                        </label>
                        <button className="basic__tab--setting__elem--company__logo--button__cross" onClick={deleteLogo}>
                            <img src={CrossBlue}/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="basic__tab--setting__elem">
                <h2 className="basic__tab--setting__elem--h2">Название</h2>
                <div className="basic__tab--setting__elem--name__company">
                    <input className="basic__tab--setting__elem--name__input" value={nameCompany}
                           onChange={changeNameCompany} placeholder="Название компании" type="text"/>
                </div>
            </div>
            <div className="basic__tab--setting__elem">
                <h2 className="basic__tab--setting__elem--h2">Слоган</h2>
                <div className="basic__tab--setting__elem--name__company">
                    <textarea className="basic__tab--setting__elem--name__input" value={descriptionCompany}
                              onChange={changeDescriptionCompany} placeholder="Текст слогана" cols={30} rows={2}/>
                </div>
            </div>
            <div className="basic__tab--setting__elem">
                <h2 className="basic__tab--setting__elem--h2">Тема</h2>
                <div className="basic__tab--setting__elem--topic">
                    <h2>Стандартная тема</h2>
                </div>
            </div>
            {checkUpdate() == true &&
                <div className="basic__tab--setting__elem--buttons">
                    <button className="basic__tab--modal__window--block__button--close" onClick={defaultsettings}>
                        {"Отмена"}
                    </button>
                    <button className="basic__tab--modal__window--block__button--crop" onClick={saveChange}>
                        {"Сохранить"}
                    </button>
                </div>
            }
        </div>
    );
};

export default BasicTab;