import ReactDOM from 'react-dom/client';
import App from './App';
import Store from "./store/store";
import { createContext } from "react";
import "./globalstyles/colors.css"
import "./globalstyles/size.pc.css"
import "./globalstyles/scrollbar.pc.css"
import '@fontsource-variable/montserrat';
import './globalstyles/input.pc.css'
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

interface State {
    store: Store;
}

const store = new Store();

export const Context = createContext<State>({
    store,
})

let SYSTEM_URL = ''
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    SYSTEM_URL = 'http://localhost:3000'
}

export const API_URL = `${SYSTEM_URL ?? ''}/api`

const $api= axios.create({
    withCredentials: true,
    baseURL: API_URL
});

$api.interceptors.request.use((config) => {
    return config;
})

// Обработчик ответов от сервера, если возвращается 401, то выставляется флаг аутентификации
$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    if(error.response.status === 401) {
        store.logout();
    }
    throw error;
})

export default $api;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Context.Provider value={{ store }}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Context.Provider>
);
